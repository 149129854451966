import firebase from "firebase/app";
import "firebase/messaging";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCHVzTmDweTzKQZvcqs5ZvUNZ34iHh8Njo",
  authDomain: "sm-bali-c6488.firebaseapp.com",
  projectId: "sm-bali-c6488",
  storageBucket: "sm-bali-c6488.appspot.com",
  messagingSenderId: "371504374659",
  appId: "1:371504374659:web:dc00787b8614b1598fccb6",
  measurementId: "G-V52J5TFCPF"
};

try {
  firebase.initializeApp(firebaseConfig);
  var messaging = firebase.messaging();
  // const { REACT_APP_VAPID_KEY } = process.env;
  var publicKey = "BPf7omikahSOjYIi0NEIOFEfBqwQYP2E9gMImo6YMrfaQeS5NNbOkRTqE8GHX1wixPLbC6tQB0d0sINB5Gt2zTg";

} catch (err) {
  // we skip the "already exists" message which is
  // not an actual error when we're hot-reloading
  if (!/already exists/.test(err.message)) {
    console.error('Firebase initialization error', err.stack)
  }
}

export const getToken = async (setTokenFound, setGetToken) => {
  let currentToken = "";
  try {
    currentToken = await messaging.getToken({ vapidKey: "BPf7omikahSOjYIi0NEIOFEfBqwQYP2E9gMImo6YMrfaQeS5NNbOkRTqE8GHX1wixPLbC6tQB0d0sINB5Gt2zTg" });
    if (currentToken) {
      setTokenFound(true);
      setGetToken(currentToken);
    } else {
      setTokenFound(false);
      setGetToken("token tidak ada");
    }
  } catch (error) {
    console.log("An error occurred while retrieving token. ", error);
  }

  return currentToken;
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    messaging.onMessage((payload) => {
      resolve(payload);
    });
  });

