import React from 'react'
import Image from "next/image"

export default function LoginLayouts(props) {
  return (
    <>
      <link rel="icon" href="/images/algorskorl.png"></link>
      <div className={`bg-[url('/images/bgd-hrm.png')] bg-cover bg-center h-screen`}>
        <div className="grid mx-auto justify-center animate__animated animate__fadeInDown">
          <div className="mx-auto mt-[7%]">
            <div className="text-center -mb-16">
              <Image src='/assets/images/header-algors.svg' width={170} height={170} />
            </div>
            <div className='text-center bg-[#131212f8] rounded-md p-10'>
              <div className=''>
                <h1 className="font-bold uppercase text-white text-[32px]">{props.title}</h1>
                <h3 className="text-[14px] font-bold text-white -mt-6">{props.subtitle}</h3>
                <div className='text-left'>
                  {props.children}  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
