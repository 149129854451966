import React, {useEffect, useState} from "react";
import { DivCustom, SelectPolda, SelectPolres } from "../MainCode/MainForm";
import { getSession, useSession } from "next-auth/react"

export default function FormPoldaPolres(props){
    const { data: session, status } = useSession()
    const [polda_id, setPoldaId] = useState(session?.polda_id);
    const [polres_id, setPolresId] = useState(session?.polres_id);

    useEffect(()=>{
      setVariabel()
    },[])

    const setVariabel = async () =>{
      const sesi = await getSession()
      sesi.polda_id && setPoldaId(sesi.polda_id)
      sesi.polres_id && setPolresId(sesi.polres_id)
    }


    return(
      <div className='grid xl:grid-cols-2 md:grid-cols-2 gap-2'>
            <DivCustom label="Polda" className={props.className ? props.className : '' }
                form={
                  <SelectPolda onChange={(e) => {props.polda(e.value) , setPoldaId(e.value)}} value={polda_id ? polda_id : props.value_polda} placeholder="Pilih Polda" disabled={session?.kesatuan == "POLDA" ? true : session?.kesatuan == "POLRES" ? true :false}/>
                } required={true} />
            <DivCustom label="Polres" className={props.className ? props.className : ''}
                form={
                  <SelectPolres onChange={(e) => {props.polres(e.value),setPolresId(e.value)}} value={polres_id ? polres_id : props.value_polres} param={polda_id ? polda_id : props.value_polda} placeholder="Pilih Polres" disabled={session?.kesatuan == "POLRES" ? true : false} />
                } required={true}/>
        </div>
    )
}