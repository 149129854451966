import dynamic from 'next/dynamic';
import { useEffect } from 'react';
const MapsAddress = dynamic(import('./MapsDetail'), { ssr: false });

const MapDetail = ({ lat, lng, jalan,zoom,multiMarker,centerMap}) => {
  useEffect(() => {

  })
  return (
    <MapsAddress jalan={jalan} lat={lat} lng={lng} zoom={zoom} multiMarker={multiMarker} centerMap={centerMap}/>
  );
};

export default MapDetail