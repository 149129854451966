import React, { useState, useEffect } from 'react'
import { StorageApi } from '../../components/MainCode/MainImport';
import { Select } from 'antd';
const { Option } = Select;

export function CrudSelect(props) {
  const [list, setDetail] = useState([]);
  const [value, setValue] = useState((e) => props.values);
  const [option, setOption] = useState((e) => props.options);

  useEffect(e => {
    if (props.effect.to == props.name) {
      ListData({
        link: props.link + '?' + props.effect.result_query
      });
      // console.log('Ini daerah yg dituju')
    } else {
      ListData({
        link: props.link
      });
    }

  }, [props.effect])

  const ListData = async (data) => {
    const a = Promise.resolve(StorageApi.getRelasi(data.link, false));
    a.then(value => {
      const lists = value.data.data
      setDetail(lists)
    }).catch(e => {
      setDetail([])
      console.log(e);
    });
  }

  return (
    <>
      <Select
        className={props.className ? props.className : 'w-100'}
        showSearch
        placeholder={props.placeholder ? props.placeholder : "Pilih Data"}
        optionFilterProp="children"
        onChange={props.onChange}
        onSearch
        allowClear
        value={props.value}>
        {list.map(item => (
          <Option key={item[value]} value={item[value]}>{item[option]}</Option>
        ))}
      </Select>
    </>
  )
}


// export function ManualSelect(props) {
//   <Select
//     className={props.className ? props.className : 'w-100'}
//     showSearch
//     placeholder={props.placeholder ? props.placeholder : "Pilih Data"}
//     optionFilterProp="children"
//     onChange={props.onChange}
//     onSearch
//     allowClear
//     value={props.value}>
//     {props?.data.map(item => (
//       <Option key={item[value]} value={item[value]}>{item[option]}</Option>
//     ))}
//   </Select>
// }