import Link from 'next/link';
import SideBar from '../Addons/SideBar';
import BrandChumb from '../Addons/BrandChumb';
import ProfileMenu from "../Addons/ProfileMenu";
import { onMessageListener, getToken } from "../../components/MainCode/firebaseConfig";
import moment from "moment/moment";
import { useEffect, useState } from 'react';
import { getSession, useSession, signOut } from "next-auth/react"
import { message,Image,notification,Space, Button } from 'antd';
import { useRouter } from "next/router";
import { SettingApi, StorageApi } from './MainImport';

export default function BaseLayout(props) {
  const router = useRouter();
  const [audioplay, setAudio] = useState(false)
  const [api, contextHolder] = notification.useNotification();
  const [isOpen, Mobile] = useState(false);
  const [statusMode, setStatusMode] = useState(false);
  const [bgColor, setBgColor] = useState('bg-[#EAEDF7]')
  const [bgColorCard, setBgColorCard] = useState('bg-[#FFFFFF]')
  const [colorText, setColorText] = useState('text-[#000000]')
  const [vscreen, setVscreen] = useState(false)
  const [loading, setLoading] = useState(false);
  const [runing_teks, setRuningText] = useState([]);
  const { data: session, status } = useSession()
  const [token, setGetToken] = useState("");
  const [errorImage, setImageError] = useState("");
  const [fcm_token, setFcmToken] = useState("");
  const [istokenfound, setTokenFound] = useState(false)
  var fcm_server_key = "AAAAVn9i44M:APA91bFb3uxb-Whj7Knt1tcngeX9UpGX5boIP_JYBT-zvJPSA5grTqQM3bHUMXpGMDLS_u-5iaTGzLN2OYT6qcjuYT6hcGiNDsvGxSYhZF7uqXdQF7Z7dHELWVBSnWW1QDDJXgRygFhU"
  useEffect(() => {
    cekSesi()
    GetRunningText()
    if (props.firebaseOn) {
      requestPermission()
      getToken(setTokenFound, setGetToken).then((res) => {
        if (session?.kode_unit == SettingApi.allow_notif[0] || session?.kode_unit == SettingApi.allow_notif[1]) {
          if (res) {
            setFcmToken(res);
            let topic = ''
            if (session?.kode_unit == SettingApi.allow_notif[0]) {
              topic = "public_service"
            }
            if (session?.kode_unit == SettingApi.allow_notif[1]) {
              topic = "opsdal"
            }
            subscribeTokenToTopic(res, topic)
          }
        }
      })
    }
    // getSess();
    getModeBaca()
  }, [loading])

  const cekSesi = async () => {
    let sesi = await getSession();
    if (!sesi) {
      tendang('Sesi anda telah berakhir')
    } else {
      Promise.resolve(StorageApi.getData(`sso_check?kode_login=${sesi.token_login}`))
        .then(value => {
          if (value.data.data[0].kode_login !== sesi.token_login) {
            tendang('Sesi anda telah berakhir')
          }
        }).catch(error => {
          console.log(error)
          tendang('Sesi anda telah berakhir')
        })
    }
  }

  const tendang = async (pesan) => {
    localStorage.clear()
    await signOut({ redirect: false })
    message.info(pesan)
    router.push('/');
  }

  // async function getSess() {
  //   const session = await getSession()
  //   if (!session) {
  //     router.push('/');
  //   }
  // }

  const getModeBaca = async() =>{
    const session = await getSession()
    Promise.resolve(StorageApi.getData(`sm_hrm/konfigurasi?kode_user_master=${session.kode_user_master}`))
    .then(value=>{
      const modeBaca = value.data.data[0]
      if (modeBaca) {
        setStatusMode(modeBaca.is_dark_mode)
        props.bgColorCard(modeBaca.is_dark_mode ? 'bg-[#0E0E23]' : 'bg-[#FFFFFF]')
        props.colorText(modeBaca.is_dark_mode ? 'text-[#FFFFFF]' : 'text-[#000000]')
        setBgColor(modeBaca.is_dark_mode ? 'bg-[#24243E]' :'bg-[#EAEDF7]')
        setBgColorCard(modeBaca.is_dark_mode ? 'bg-[#0E0E23]' :'bg-[#FFFFFF]')
        setColorText(modeBaca.is_dark_mode ? 'text-[#FFFFFF]' : 'text-[#000000]')
      }else{
        props.bgColorCard('bg-[#FFFFFF]')
        props.colorText('text-[#000000]')
      }
    }).catch(error => {
      setBgColor('bg-[#EAEDF7]')
      setBgColorCard('bg-[#FFFFFF]')
      setColorText('text-[#000000]')
      props.bgColorCard('bg-[#FFFFFF]')
      props.colorText('text-[#000000]')
    })
  }

  async function requestPermission() {
    const permisson = await Notification.requestPermission()
    if (permisson === 'granted') {
      message.info("Notifikasi di izinkan")
    } else if (permisson === 'denied') {
      alert('Mohon izinkan notifikasi browser anda, agar sistem berjalan dengan baik')
    }
  }

  if (props.firebaseOn) {
      onMessageListener().then(async(payload) => {
        setAudio(true)
        const sesi = await getSession()
        if (sesi.kode_unit == SettingApi.allow_notif[0]) {
          const btn = (
            <Space>
              <Button type="link" size="small" onClick={() => router.push('/PublicService/rekap')}>
                Lihat Laporan 
              </Button>
            </Space>
          );
          api.open({
            message: 'Laporan Masuk',
            description:`${payload.notification.body}`,
            btn, 
          });
        }else{
          api.open({
            message: 'Laporan Masuk',
            description:`${payload.notification.body}`,
          });
          message.info(`${payload.notification.body}`, 10)
        }
      }).catch((err) => {
        setAudio(false)
        console.log("failed: ", err);
      });
   
  }

     //set notification to firebase
     function subscribeTokenToTopic(api_token, topic) {
      fetch('https://iid.googleapis.com/iid/v1/' + api_token + '/rel/topics/' + topic, {
        method: 'POST',
        headers: new Headers({
          'Authorization': 'key=' + fcm_server_key
        })
      }).then(response => {
        if (response.status < 200 || response.status >= 400) {
          throw 'Error subscribing to topic: ' + response.status + ' - ' + response.text();
        }
        console.log('Subscribed to "' + topic + '"');
      }).catch(error => {
        console.log("ERROR Subscribe to topic", error);
      })
    }

  const GetRunningText = async () => {
    var categori = await getSession()
    let arr = []
    if (categori.polda_id) { arr = [...arr, `broad_cast_to_polda=${categori.polda_id}`] }
    if (categori.polres_id) { arr = [...arr, `broad_cast_to_polres=${categori.polres_id}`] }
    let param = '';
    param = arr.length > 0 ? '?' + arr.join('&') : '';
    Promise.resolve(StorageApi.getData('sm_master_data/running_text_by_session' + param))
      .then(value => {
        var list = value.data.data;
        
        setRuningText(list)
      }).catch(error => {
        setRuningText([])
      })
  }

  const fullScreen = (vscreen) =>{
    setVscreen(vscreen)
    const element = document.documentElement;
    if (vscreen) {
      Mobile(vscreen)
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen();
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
      }
    }else{
      Mobile(vscreen)
       if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
    }
  }

  const setCookieModeBaca = async(param) =>{
    const session = await getSession()
    const data = {
      'kode_user_master' : session.kode_user_master,
      'is_dark_mode' : param,
      'ctdby' : session.kode_user_master,
      'ctdupd' : moment().format("YYYY-MM-DD")
    }
    Promise.resolve(StorageApi.addData('sm_hrm/konfigurasi',data,false))
    .then(value=>{
      message.info('Berhasil mengubah mode baca')
      getModeBaca()
    }).catch(error=>{
      
    })
  }

  return (
<>

    {/* <Head>
        <title>{props.pageTitle}</title>
        <link rel="icon" href="/images/algorskorl.png"></link>
        <LoadScript />
      </Head> */}
    
      <div className="flex flex-initial h-screen overflow-hidden text-[14px] ">
          {contextHolder}
          {audioplay && <div>
          <audio autoPlay>
            <source src="/sound/alarm_emergency.mp3" type="audio/mpeg" />
          </audio>
        </div>}
        <div className={` ${isOpen ? " xl:-translate-x-full md:-translate-x-full -translate-x-0 transition-all duration-500 xl:-ml-[16rem] md:-ml-[16rem]" : " xl:-translate-x-0 md:-translate-x-0 transition-all duration-500 -translate-x-full"} shadow-[rgba(0,0,15,0.5)_-12px_10px_42px_0px] z-10 transition duration-500 absolute md:relative xl:relative`}>
          <div className={`${bgColorCard} w-[17rem]`}>
            <div className=" text-center items-center flex-shrink-0 mb-2">
              <div className='p-5'>
                <Link href={`/embed-file/embed-peta`}>
                  <a className="text-left focus:outline-none">
                    <Image className="inline-block"
                      src={ errorImage ? errorImage :  session?.logo_bo ? session?.logo_bo : '/assets/images/algorskorl.png'}
                      alt="image icon"
                      width={errorImage ? 130:165} 
                      onError={e=>setImageError('/images/profile.png')}
                      preview={false}/>
                  </a>
                </Link>
              </div>
              <div className=''>
                <div className="bg-gradient-to-r from-[#1E42B0] to-[#07B5D4] p-4  font-sans tracking-wider text-[16px] text-white font-bold justify-center text-center mt-3">
                  <span>{session?.unit_fungsional}</span>
                </div>
              </div>
            </div>
            <div className={` ${bgColorCard} ${isOpen ? "z-10" : ""}  pb-60 h-screen overflow-y-auto scrollbar-hide`}>
                <SideBar aktif={props.aktif} colorText={colorText} bgColor={bgColor} statusMode={statusMode}/>
            </div>
          </div >
        </div >

        <div className={` ${bgColor} basis-[100%] grid grid-cols-1 overflow-hidden`}>
          <div className={`${isOpen ? 'px-6' : ''} flex items-center ${bgColorCard}`}>
            <div className="container flex ml-3">
              <button className={`mr-4 z-50`} onClick={() => Mobile(!isOpen)}>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-7 w-7" fill="none" viewBox="0 0 24 24" stroke={`${statusMode ? '#FFFFFF':'#000000'}`} strokeWidth={2}>
                  <path  strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
                </svg>
              </button>
              <marquee direction="" width="80%">
              {/* <marquee direction="" width="100%" onMouseover="this.stop();" onMouseout="this.start();"> */}
                <div className='relative overflow-visible inline float-left'>
                  <div className="inline">
                    {runing_teks.map(item => {
                      return(<>
                      &nbsp; &nbsp; <Image src={`/icons/${item.icon_name}`} width={20} height={20} alt="" preview={false} /> &nbsp; <a className="no-underline font-bold text-red-700 text-base fancybox" data-fancybox-group="gallery" href="#!" title="THE ELECTRIC LIGHTING ACT: section 35">{`${item.judul_pesan}`}: {item.konten}</a> &nbsp; <Image src={`/icons/${item.icon_name}`} width={20} height={20} alt="" preview={false} />
                      </>)
                    })}
                  </div>
                </div>
              </marquee>
            </div>
            <div className=" w-screen mb-3">
              <div className=" flex flex-row justify-end mt-4 align-middle">
                <div className='flex-row flex mt-1 -mr-5'>
                  <div className='basis-2 mr-3 mt-1'>
                    <Image className='cursor-pointer hover:translate-x-0 hover:scale-95 rotate-[270deg]'  src={`${statusMode ? '/assets/icons/sm_sun.png' : '/assets/icons/sm_moon.png'}`} width={40} preview={false} onClick={e=>setCookieModeBaca(!statusMode)} />
                  </div>
                  <div className='basis-2 mt-2 hidden xl:block md:block'>
                  <Image className='cursor-pointer hover:translate-x-0 hover:scale-95'  src={`${statusMode ? '/assets/icons/fullscreenW.png': '/assets/icons/fullscreen.png'}`} width={30} preview={false} onClick={e=>fullScreen(!vscreen)}/>
                  </div>
                </div>
                <div className='text-left'>
                 <ProfileMenu token={fcm_token} colorText={colorText} bgColorCard={bgColorCard} />
                </div>
                {/* <Image src={`${props.statusmode ? '/assets/icons/moon.png' : '/assets/icons/matahari.png'}`} width={25} /> */}
              </div>
            </div>
          </div>
          {/* <div className="container bg-[#EAEDF7] "> */}
            <div className={`${isOpen ? 'px-4' : ''} mt-6 min-w-full`}>
              <BrandChumb subTitle={props.subTitle} colorText={colorText} Downloaddata={props.Downloaddata} Adddata={props.Adddata == "hidden" ? false : true} renGiat={props.renGiat} renGiatMas={props.renGiatMas} renOps={props.renOps} satuPeta={props.satuPeta} onClick={props.onClick} />
              <div className='h-screen overflow-y-auto scrollbar-hide'>
                <div className="px-2">
                  <div className="p-2">
                    {props.children}
                  </div>
                  </div>
                </div>
            </div>
          {/* </div> */}
        </div>
      </div>
      </>
  );

}
