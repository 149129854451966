import React, { useState, useEffect, Fragment, useRef } from 'react'
import Image from 'next/image';
import { useRouter } from 'next/router';
import { Space, Table, Form, message, Button, Modal, Input, Tooltip, Popconfirm, DatePicker } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { CrudSelect } from './CrudSelect';
import { useCookies } from 'react-cookie';
import {
  StorageApi,
} from '../../components/MainCode/MainImport';
import { getSession } from "next-auth/react"

const { Column, ColumnGroup } = Table;

const Crud = [];

Crud.table = (data) => {

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisibleEdit, setIsModalVisibleEdit] = useState(false);
  const [DATA_CORE, setDataCore] = useState((e) => data)
  const [list, setList] = useState([])
  const [effect, setEffect] = useState([])
  const [cookies, setCookie, removeCookie] = useCookies(['rowid', 'kode_unit']);
  const [valid, setValid] = useState({
    insert: true,
    delete: true,
    edit: true
  })
  const router = useRouter();
  const link_akses = router.asPath.split('/')
  const link_aktif = link_akses[link_akses.length - 1]


  useEffect(() => {
    AllData();
    getValidasi();

  }, [])

  /*
    ============
    = Validasi =
    ============
  */
  const getValidasi = async () => {
    const session = await getSession()
    const a = Promise.resolve(StorageApi.getData(`sm_hrm/role_user?kode_user_master=${session.kode_user_master}`))
    a.then(x => {
      let r = x.data.data[0];
      valid.insert = r.tambah;
      valid.edit = r.edit;
      valid.delete = r.hapus;
      setValid({ ...valid })
    }).catch(e => {
      console.log(e)
    })
  }

  /*
    ==========================
    = GET ALL DATA WITH API  =
    ==========================
  */

  const AllData = async () => {
    setList([])
    let { data: rsp } = await Promise.resolve(StorageApi.getData(DATA_CORE.api.get_all));
    if (rsp.status) {
      setList(rsp.data.map((e) => ({ ...e, key: e[data.key] })))
    }

    return false;
  }

  /*
    =============
    = POST API  =
    =============
 */
  const addSubmit = async (data_post) => {
    try {
      let { data: rsp } = await Promise.resolve(StorageApi.addData(DATA_CORE.api.post, data_post, false));
      if (rsp.status) {
        setLoading(false);
        setIsModalVisible(false)
      } else {
        setLoading(false);
      }

      return rsp;
    } catch (error) {
      setLoading(false)
      return {
        status: false,
        msg: error.response.data.msg
      }
    }

  }

  /*
    ============
    = PUT API  =
    ============
  */
  const editSubmit = async (data_put = {}, id = null) => {
    let { data: rsp } = await Promise.resolve(StorageApi.editData(DATA_CORE.api.put, data_put, id, false));
    if (rsp.status) {
      setLoading(false);
      setIsModalVisibleEdit(false)
    } else {
      setLoading(false);
    }

    return rsp;
  }

  /*
  ============
  = DELETE API  =
  ============
*/
  const deleteData = async (id = null) => {
    try {
      let { data: rsp } = await Promise.resolve(StorageApi.hapusDataNew(DATA_CORE.api.put, id, false));
      if (rsp.status) {
        setLoading(false);
        AllData()
        message.success("Berhasil menghapus data")
      } else {
        setLoading(false);
        message.error("Gagal menghapus data")
      }
    } catch (error) {
      message.error("Gagal menghapus data")
    }
  }


  const handleOk = (param = "add") => {
    if (param == "edit") {
      setIsModalVisibleEdit(false);
      return;
    }
    setIsModalVisible(false);
  };

  const handleCancel = (param = "edit") => {
    if (param == "edit") {
      setIsModalVisibleEdit(false);
      return;
    }
    setIsModalVisible(false);
  };

  const [requiredMark, setRequiredMarkType] = useState('optional');

  const onRequiredTypeChange = ({ requiredMarkValue }) => {
    setRequiredMarkType(requiredMarkValue);
  };

  const onResetInput = () => {
    let values = form.getFieldValue();
    for (const i in values) {
      if (Object.hasOwnProperty.call(values, i)) {
        const element = values[i];
        if (Number.isInteger(parseInt(i))) {
          form.setFieldsValue({ [element]: '' })
        } else {
          form.setFieldsValue({ [i]: '' })
        }
      }
    }

    return false;
  }

  const onFinish = async (values) => {
    values = { ...values, ...Object.fromEntries(data.add.input.filter(z => z.show == false).map(({ name, value }) => [name, value])) }
    let i = await addSubmit(values);
    if (i.status) {
      onResetInput(values);
      setIsModalVisible(false);
      await AllData();
      message.success("Berhasil menambahkan data");
    } else {
      message.error(i.msg);
    }

    setLoading(false)
  };

  const onFinishFailed = () => {
    message.error('Submit failed!');
  };


  // Edit
  const onFinishEdit = async () => {
    let values = form.getFieldValue();
    values = { ...values, ...Object.fromEntries(data.edit.input.filter(z => z.show == false).map(({ name, value }) => [name, value])) }
    let i = await editSubmit(values, values.key);
    if (i.status) {
      onResetInput(values);
      setIsModalVisibleEdit(false);
      await AllData();
      message.success("Berhasil mengubah data");
    } else {
      message.error("Gagal mengubah data");
    }

    setLoading(false)
  };

  const onFinishFailedEdit = () => {
    message.error('Submit failed!');
  };

  const onFill = (e) => {
    setLoading(true);
  };

  const onFillEdit = (e) => {
    setLoading(true);
  };

  const onHandleEdit = (x) => {
    let detail = list.filter(e => e.key == x)[0]
    form.setFieldsValue({ key: x });
    data.edit.input.filter(x => x.show && x.show == true).forEach((e, i) => {
      form.setFieldsValue({ [e.name]: detail[e.name] });
    });
    setIsModalVisibleEdit(true)

  }

  // Search

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  // Effect
  const getEffect = (value) => {
    try {
      let v = Object.values(value.query).map((e, i) => e + (form.getFieldValue(Object.keys(value.query)[i]) != undefined ? form.getFieldValue(Object.keys(value.query)[i]) : '')).join('&')
      setEffect({ ...value, result_query: v })
    } catch (error) {
      console.log('')
    }

  }

  return (
    <>
      {valid.insert ? (
        <div className='mb-3'>
          <Button type="primary" className='' onClick={(e) => {
            setIsModalVisible(!isModalVisible)
            onResetInput();
          }}>
            Tambah Data
          </Button>
        </div>
      ) : <></>}
      <div className='pb-96'>
        <div className='overflow-x-auto bg-white'>
          <Table loading={loading} dataSource={list} pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '30'] }}>
            <Column
              title="No"
              key="no"
              render={(a, b, c) => (
                <>{c += 1}</>
              )}
            />

            {
              data.thead.map((item, i) => {
                return (
                  <Fragment key={item.key}>
                    <Column title={item.title} dataIndex={item.key} {...getColumnSearchProps(item.key)} key={item.key} render={item.render} />
                  </Fragment>
                )
              })
            }
            {link_aktif != "modul" ? (<>
              {valid.delete || valid.insert || valid.edit ? (
                <Column
                  title="Action"
                  key="action"
                  render={(item) => (
                    <div>
                      {valid.edit ? (
                        <Tooltip title="Edit" placement="left">
                          <button className="hover:text-blue-500 mr-1 border hover:border-[#326AE3] p-1 border-white rounded-sm" onClick={(e) => onHandleEdit(item.key)} >
                           <Image className='' src="/icons/editW.svg" alt="edit" width={20} height={20} />
                          </button>
                        </Tooltip>) : <></>}

                      {valid.delete ? (
                        <Popconfirm
                          title="Apakah anda yakin ingin menghapus data ini ?"
                          onConfirm={(e) => deleteData(item.key)}
                          onCancel={(e) => console.log('gak jadi cuaks')}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Tooltip title="Hapus" placement="right">
                            <button className="hover:text-blue-500 mr-1 border hover:border-[#326AE3] p-1 border-white rounded-sm">
                              <Image src='/icons/trashW.svg' width={25} height={23} alt={'edit'} />
                            </button>
                          </Tooltip>
                        </Popconfirm>
                      ) : <></>}
                    </div>
                  )}
                />
              ) : <></>}
            </>) : (<></>)}
          </Table>
        </div>
      </div>

      <Modal title={data.add.title ? data.add.title : 'Tambah Data'} visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} footer={null}>
        <Form
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          fields={data.add.input}
          layout="vertical"
        >
          {
            data.add.input.filter(e => e.show && e.show == true).map((item, i) => {
              return (
                <Fragment key={item.name}>
                  <Form.Item label={item.title} required name={item.name} tooltip={item.required} rules={item.rules} >
                    {
                      item.type == "select" ?
                        (<CrudSelect placeholder={item.placeholder} name={item.name} onChange={(e) => getEffect({ ...item.select.effect, from: item.name })} effect={effect} link={item.select.link} values={item.select.value} options={item.select.option} value={form.getFieldValue(item.name)} />
                        ) : item.type == "date" ? (
                          <DatePicker className='w-full' />
                        ) : (
                          <Input placeholder={item.placeholder} />)
                    }
                  </Form.Item>
                </Fragment>
              )
            })
          }

          <Form.Item>
            <Button loading={loading} type="primary" htmlType="submit" onClick={onFill}>Simpan</Button>
          </Form.Item>
        </Form>
      </Modal>

      <Modal title={data.edit.title ? data.edit.title : 'Edit Data'} visible={(isModalVisibleEdit)} onOk={(e) => handleOk('edit')} onCancel={(e) => handleCancel('edit')} footer={null}>
        <Form
          form={form}
          onFinish={onFinishEdit}
          onFinishFailed={onFinishFailedEdit}
          layout="vertical"
          onValuesChange={onRequiredTypeChange}
        >
          {
            data.edit.input.filter(e => e.show && e.show == true).map((item, i) => {
              return (
                <Fragment key={item.name}>
                  <Form.Item label={item.title} required name={item.name} tooltip={item.required} rules={item.rules}>
                    {
                      item.type == "select" ?
                        (<CrudSelect placeholder={item.placeholder} name={item.name} onChange={(e) => getEffect({ ...item.select.effect, from: item.name })} effect={effect} link={item.select.link} values={item.select.value} options={item.select.option} value={form.getFieldValue(item.name)} />)
                        : item.type == "date" ? (<DatePicker className='w-full' />)
                          : (<Input placeholder={item.placeholder} />)
                    }
                  </Form.Item>
                </Fragment>
              )
            })
          }

          <Form.Item>
            <Button type="primary" loading={loading} htmlType="submit" onClick={onFillEdit}>Ubah</Button>
          </Form.Item>
        </Form>
      </Modal>
    </>)
}

export default Crud