import React, { useEffect, useState } from 'react'
import { DatePicker } from 'antd'
import { useSession } from "next-auth/react"
import Image from 'next/image'

import {
  moment,
  DivCustom,
  InputCustom,
  SelectPendidikan,
  SelectSertifikasi,
  SelectPelatihan,
  SelectPolda,
  SelectPolres,
  SelectPangkat,
  SelectJabatan,
  SelectSotk,
  SelectModul,
  SelectCustom,
  SelectFungsiKepolisian,
  SelectSubFungsiKepolisian

} from '../MainCode/MainImport'

export function FormPendidikan(props) {
  const [tempat_pendidikan, setTempatPendidikan] = useState('');
  const [bid_studi, setBidStudi] = useState('');
  const [pendidikan_id, setPendidikan] = useState('');
  const [berakhir, setTglBerakhir] = useState('');
  const [mulai, setTglMulai] = useState('');
  const [kesatuan, setKesatuan] = useState('');
  const [pendidikan_paran, setPendidikanParam] = useState('');
  const [extension, setExtension] = useState('');




  const { data: session, status } = useSession()

  useEffect(() => {
    setData(props.data)
    props.data ? '' : reset_elm()
  }, [props.data])

  const setData = (values) => {
    if (values[0] != undefined) {
      const value = values[0]
      setPendidikan(value.pendidikan_id)
      setTempatPendidikan(value.tempat_pendidikan)
      setBidStudi(value.bid_studi)
      setTglBerakhir(moment(value.berakhir).format('YYYY-MM-DD'))
      setTglMulai(moment(value.mulai).format('YYYY-MM-DD'))
    }
  }

  const reset_elm = () => {
    setPendidikan()
    setTglBerakhir()
    setTglMulai()
    setTempatPendidikan()
    setBidStudi()
  }

  const PendidikanParam = (e) => {
    setPendidikanParam(e.label)
    setPendidikan(e.value)
  }

  

  const sendPendidikan = (param, d) => {
    const data = {
      kode_user_master: session?.kode_user_master,
      ctdby: session?.kode_user_master,
    }

    if (param == "pendidikan") {
      setPendidikan(d)
      data.pendidikan_id = d
      data.mulai = mulai
      data.berakhir = berakhir
      data.tempat_pendidikan = tempat_pendidikan
      data.bid_studi = bid_studi
    }
    if (param == "mulai") {
      setTglMulai(d)
      data.pendidikan_id = pendidikan_id
      data.mulai = d
      data.berakhir = berakhir
      data.tempat_pendidikan = tempat_pendidikan
      data.bid_studi = bid_studi
    }
    if (param == "berakhir") {
      setTglBerakhir(d)
      data.pendidikan_id = pendidikan_id
      data.mulai = mulai
      data.berakhir = d
      data.tempat_pendidikan = tempat_pendidikan
      data.bid_studi = bid_studi
    } else if (param == "tempat_pendidikan") {
      setTempatPendidikan(d)
      data.pendidikan_id = pendidikan_id
      data.mulai = mulai
      data.berakhir = berakhir
      data.tempat_pendidikan = d
      data.bid_studi = bid_studi
    } else if (param == "bid_studi") {
      setBidStudi(d)
      data.pendidikan_id = pendidikan_id
      data.mulai = mulai
      data.berakhir = berakhir
      data.tempat_pendidikan = tempat_pendidikan
      data.bid_studi = d
    }
    const { callBack } = props
    callBack(data)
  }

  return (
    <>
      <div className="grid xl:grid-cols-2 md:grid-cols-2 gap-3">
        <DivCustom label="Nrp"
          form={
            <>
              <InputCustom value={session?.id_unique} placeholder="Nama Anggota" readOnly={true} />
            </>
          }
        />
        <DivCustom label="Nama"
          form={
            <InputCustom value={session?.nama} placeholder="Nama Anggota" readOnly={true} />
          } />
      </div>
      <div className="grid xl:grid-cols-2 md:grid-cols-2 gap-3">
        <DivCustom label={`${props.param == 'dikbang' ? "Nama Dikbang Umum" : props.param == 'dikbang spes' ? "Nama Dikbang Spesial": "Nama Sekolah"}`}
          form={
            <InputCustom value={tempat_pendidikan ? tempat_pendidikan : ""} onChange={e => sendPendidikan('tempat_pendidikan', e.target.value)} placeholder={`${props.param == 'dikbang' ? "Nama Dikbang" : props.param == 'dikbang spes' ? "Nama Dikbang Spesial": "Nama Sekolah"}`} />
          } />
        <DivCustom label={`${props.param == 'dikbang' ? "Lembaga Pendidikan" : props.param == 'dikbang spes' ? "Lembaga Pendidikan" : "Jenjang Pendidikan"}`}
          form={
            // <SelectPendidikan onChange={(e) => PendidikanParam('pendidikan', e.value)} value={pendidikan_id} placeholder={`Pilih ${props.param}`} param={props.param} />
            <SelectPendidikan onChange={(e) => PendidikanParam(e)} value={pendidikan_id}  placeholder={`Pilih ${props.param}`} param={props.param} />

          } />
           {props.param == 'dikbang' || props.param == 'dikbang spes'  ? 
        (<></>) :
          (<>
          {pendidikan_paran == "SD" || pendidikan_paran == "SMP" ? (<></>) : (<>
            <DivCustom label="Bidang Studi"
              form={
                <InputCustom value={bid_studi ? bid_studi : ""} onChange={e => sendPendidikan('bid_studi', e.target.value)} placeholder="Bidang Studi" />
              } />
          </>)}
        </>)}

        <DivCustom label="Mulai"
          form={
            <DatePicker className='w-full' value={mulai ? moment(mulai, 'YYYY-MM-DD') : ""} onChange={(e, d) => sendPendidikan('mulai', d)} picker="date" />
          } />

        <DivCustom label="Berakhir"
          form={
            <DatePicker className='w-full' value={berakhir ? moment(berakhir, 'YYYY-MM-DD') : ""} onChange={(e, d) => sendPendidikan('berakhir', d)} picker="date" />
          } />
      </div>
      <div className="grid xl:grid-cols-2 md:grid-cols-2 gap-3">
         
      </div>

    </>
  )
}

export function FormPelatihanDanSertifikasi(props) {
  const [penerbit, setPenerbit] = useState('');
  const [no_sertifikasi, setNoSertifikasi] = useState('');
  const [penyelenggara, setPenyelenggara] = useState('');
  const [nama_lembaga, setNamaLembaga] = useState('');
  const [nama_pelatihan, setNamaPelatihan] = useState('');
  const [nama_sertifikasi, setNamaSertifikasi] = useState('');
  const [jenis_sertifikasi_id, setSertifikasiId] = useState('');
  const [jenis_pelatihan_id, setPelatihanId] = useState('');
  const [tgl_terbit, setTglTerbit] = useState('');
  const [berakhir, setTglBerakhir] = useState('');
  const [masa_berlaku, setMasaBerlaku] = useState(''); 
  const [file_pelatihan, setFilePelatihan] = useState('');
  const [file_sertifikasi, setFileSertifikasi] = useState('');
  const [extension, setExtension] = useState('');
  const [show_images, setShowImage] = useState('');
  const [golongan, setGolongan] = useState('');
  const { data: session, status } = useSession()


  useEffect(() => {
    setData(props.data)
    props.data ? '' : reset_elm()
  }, [props.data])

  const setData = (values) => {
    if (values[0] != undefined) {
      const value = values[0]
      setSertifikasiId(value.jenis_sertifikasi_id != undefined ? value.jenis_sertifikasi_id : "")
      setPelatihanId(value.jenis_pelatihan_id != undefined ? value.jenis_pelatihan_id : "")
      setPenyelenggara(value.nama_lembaga)
      setFilePelatihan(value.file_pelatihan)
      setNamaPelatihan(value.nama_pelatihan)
      setNamaSertifikasi(value.nama_sertifikasi)
      setNoSertifikasi(value.no_sertifikasi)
      setTglTerbit(moment(value.tgl_terbit).format('YYYY-MM-DD'))
      // setTglBerakhir(moment(value.berakhir).format('YYYY-MM-DD'))
      setMasaBerlaku(moment(value.masa_berlaku).format('YYYY-MM-DD'))
    }
  }
  const reset_elm = () => {
    setSertifikasiId('')
    setPelatihanId('')
    setTglTerbit('')
    setMasaBerlaku('')
    setPenerbit('')
    setGolongan('')
    setPenyelenggara('')
    setNamaPelatihan('')
    setNamaSertifikasi('')
    setFilePelatihan('')
    setShowImage('')

  }

  const UpdtGambar = (e) => {
    const [file] = e.target.files;
    setShowImage(URL.createObjectURL(file))
    setExtension(file.name.split('.').pop())
    setFilePelatihan(e.target.files[0])
  }

  const SendPelDanSer = (param, d) => {
    const data = {
      kode_user_master: session?.kode_user_master,
      ctdby: session?.kode_user_master,
    }
    if (param == "pelatihan") {
        setPelatihanId(d)
        data.jenis_pelatihan_id = d
        data.nama_pelatihan = nama_pelatihan
        data.nama_lembaga = penyelenggara
        data.berakhir = masa_berlaku
        data.file_pelatihan = file_pelatihan
    } else if (param == "sertifikasi") {
        setSertifikasiId(d)
        data.jenis_sertifikasi_id = d
        data.nama_sertifikasi = nama_sertifikasi
        data.nama_lembaga = penyelenggara
        data.tgl_terbit = tgl_terbit
        data.masa_berlaku = masa_berlaku
        data.no_sertifikasi = no_sertifikasi
        data.file_sertifikasi = file_pelatihan
    } else if (param == "nama_pelatihan") {
        setNamaPelatihan(d)
        data.jenis_pelatihan_id = jenis_pelatihan_id
        data.nama_pelatihan = d
        data.nama_lembaga = penyelenggara
        data.berakhir = masa_berlaku
        data.file_pelatihan = file_pelatihan
    } else if (param == "nama_sertifikasi") {
        setNamaSertifikasi(d)
        data.jenis_sertifikasi_id = jenis_sertifikasi_id
        data.nama_sertifikasi = d
        data.nama_lembaga = penyelenggara
        data.tgl_terbit = tgl_terbit
        data.masa_berlaku = masa_berlaku
        data.no_sertifikasi = no_sertifikasi
        data.file_sertifikasi = file_pelatihan
    }
    else if (param == "penyelenggara") {
      if (jenis_pelatihan_id) {
          setPenyelenggara(d)
          data.jenis_pelatihan_id = jenis_pelatihan_id
          data.nama_pelatihan = nama_pelatihan
          data.nama_lembaga = d
          data.berakhir = masa_berlaku
          data.file_pelatihan = file_pelatihan
        }
      if (jenis_sertifikasi_id) {
        setPenyelenggara(d)
        data.jenis_sertifikasi_id = jenis_sertifikasi_id
        data.nama_sertifikasi = nama_sertifikasi
        data.nama_lembaga = d
        data.tgl_terbit = tgl_terbit
        data.masa_berlaku = masa_berlaku
        data.no_sertifikasi = no_sertifikasi
        data.file_sertifikasi = file_pelatihan
      }
    } else if (param == "masa_berlaku") {
      if (jenis_pelatihan_id) {
          setMasaBerlaku(d)
          data.jenis_pelatihan_id = jenis_pelatihan_id
          data.nama_pelatihan = nama_pelatihan
          data.nama_lembaga = penyelenggara
          data.berakhir = d
          data.file_pelatihan = file_pelatihan
      }
      if (jenis_sertifikasi_id) {
        setMasaBerlaku(d)
        data.jenis_sertifikasi_id = jenis_sertifikasi_id
        data.nama_sertifikasi = nama_sertifikasi
        data.nama_lembaga = penyelenggara
        data.tgl_terbit = tgl_terbit
        data.masa_berlaku = d
        data.no_sertifikasi = no_sertifikasi
        data.file_sertifikasi = file_pelatihan
      }
    }else if (param == "file_pelatihan") {
      if (jenis_pelatihan_id) {
        const [file] = d.target.files
        setShowImage(URL.createObjectURL(file))
        setExtension(file.name.split('.').pop())
        setFilePelatihan(d.target.files[0])
        data.jenis_pelatihan_id = jenis_pelatihan_id
        data.nama_pelatihan = nama_pelatihan
        data.nama_lembaga = penyelenggara
        data.berakhir = masa_berlaku
        data.file_pelatihan = d.target.files[0]
      }
      if (jenis_sertifikasi_id) {
      const [file] = d.target.files
        setShowImage(URL.createObjectURL(file))
        setExtension(file.name.split('.').pop())
        setFileSertifikasi(d.target.files[0])
        data.jenis_sertifikasi_id = jenis_sertifikasi_id
        data.nama_sertifikasi = nama_sertifikasi
        data.nama_lembaga = penyelenggara
        data.tgl_terbit = tgl_terbit
        data.masa_berlaku = masa_berlaku
        data.no_sertifikasi = no_sertifikasi
        data.file_pelatihan = d.target.files[0]
      } 
    }else if (param == "no_sertifikasi") {
        setNoSertifikasi(d)
        data.jenis_sertifikasi_id = jenis_sertifikasi_id
        data.nama_sertifikasi = nama_sertifikasi
        data.nama_lembaga = penyelenggara
        data.tgl_terbit = tgl_terbit
        data.masa_berlaku = masa_berlaku
        data.no_sertifikasi = d
        data.file_sertifikasi = file_pelatihan
    }
    else if (param == "tgl_terbit") {
        setTglTerbit(d)
        data.jenis_sertifikasi_id = jenis_sertifikasi_id
        data.nama_lembaga = penyelenggara
        data.tgl_terbit = d
        data.masa_berlaku = masa_berlaku
        data.no_sertifikasi = no_sertifikasi
        data.file_sertifikasi = file_pelatihan
    } 

    console.log(data);
    const { callBack } = props
    callBack(data)
  }

  return (
    <>
      <div className="grid xl:grid-cols-2 md:grid-cols-2 gap-3">
        <DivCustom label="Nrp"
          form={
            <>
              <InputCustom value={session?.id_unique} placeholder="Nama Anggota" readOnly={true} />
            </>
          }
        />
        <DivCustom label="Nama"
          form={
            <InputCustom value={session?.nama} placeholder="Nama Anggota" readOnly={true} />
          } />
      </div>

      <div className="grid xl:grid-cols-2 md:grid-cols-2 gap-3">
      
      {props.param == "sertifikasi" ? (<>
        <DivCustom label="Nama Sertifikasi"
          form={
            <InputCustom value={nama_sertifikasi} onChange={e => SendPelDanSer('nama_sertifikasi', e.target.value)} placeholder="Nama Sertifikasi"   />
        } />
        <DivCustom label="Jenis Sertifikasi"
            form={
              <SelectSertifikasi onChange={(e) => SendPelDanSer('sertifikasi', e.value)} value={jenis_sertifikasi_id} placeholder="Pilih Sertifikasi" />
        } />
        <DivCustom label="No Sertifikasi"
            form={
            <InputCustom value={no_sertifikasi} onChange={e => SendPelDanSer('no_sertifikasi', e.target.value)} placeholder="No Sertifikasi"  />
        } />
        </>) : (<>
          <DivCustom label="Nama Pelatihan"
            form={
              <InputCustom value={nama_pelatihan} onChange={e => SendPelDanSer('nama_pelatihan', e.target.value)} placeholder="Nama Pelatihan"   />
          } />
          <DivCustom label="Tipe Pelatihan"
            form={
              <SelectPelatihan onChange={(e) => SendPelDanSer('pelatihan', e.value)} value={jenis_pelatihan_id} placeholder="Pilih Pelatihan" />
            } />
        </>)}
        <DivCustom label={`${props.param == "sertifikasi" ? " Nama Lembaga" : "Nama Lembaga Pelatihan "}`}
          form={
            <InputCustom value={penyelenggara} onChange={e => SendPelDanSer('penyelenggara', e.target.value)} placeholder={`${props.param == "sertifikasi" ? "Organisasi Penerbit" : "Nama Lembaga Pelatihan"}`} />
          } />
        {props.param == "sertifikasi" ? (<>
        <DivCustom label="Tanggal Terbit"
            form={
              <DatePicker className='w-full' value={tgl_terbit ? moment(tgl_terbit, 'YYYY-MM-DD') : ""} onChange={(e, d) => SendPelDanSer('tgl_terbit', d)} picker="date" />
          } />
        </>) : (<></>)}        
        <DivCustom label={`${props.param == "sertifikasi" ? "Masa Berlaku" : "Tanggal Berakhir"}`}
          form={
            <DatePicker className='w-full' value={masa_berlaku ? moment(masa_berlaku, 'YYYY-MM-DD') : ""} onChange={(e, d) => SendPelDanSer('masa_berlaku', d)} picker="date" />
          } />
        <DivCustom label={`${props.param == "sertifikasi" ? "Upload file sertifikasi" : "Upload file/dokumentasi pelatihan"}`}
            form={
              <div>
                <input type="file" className='w-[88px]' onChange={e => SendPelDanSer('file_pelatihan',e)} />
                <div className='p-2'>
                  {
                    typeof file_pelatihan == "object" ? <p className='mb-4'>Selected file :<b> {file_pelatihan.name}</b></p> : <></>
                  }
                  {extension == "png" || extension == "jpg" || extension == "jpeg" ? (<>
                    <Image
                      loader={e => { return show_images ? show_images : "/images/image_select.png" }}
                      src={show_images ? show_images : "/images/image_select.png"}
                      alt="image icon"
                      width={200}
                      height={200} />
                  </>) : (<></>)}
                  <p className='text-[12px] text-justify -mb-1' >* Jika dokumentasi lebih dari satu foto, upload dalam bentuk pdf</p>
                  <p className='text-[12px] text-justify -mb-1' >* File maksimal <span className="text-red-500">2 Mb</span></p>
                  <p className='text-[12px] text-justify' >* Format file <span className="text-red-500 uppercase">png,jpg,jpeg,pdf</span></p>
                </div>
              </div>
          } />
      </div>
      <div className="grid xl:grid-cols-2 md:grid-cols-2 gap-3">
  
      </div>

    </>
  )
}

export function FormSertifikasi(props) {
  const [no_sertifikasi, setNoSertifikasi] = useState('');
  const [nama_lembaga, setNamaLembaga] = useState('');
  const [nama_sertifikasi, setNamaSertifikasi] = useState('');
  const [jenis_sertifikasi_id, setSertifikasiId] = useState('');
  const [tgl_terbit, setTglTerbit] = useState('');
  const [masa_berlaku, setMasaBerlaku] = useState(''); 
  const [file_sertifikasi, setFileSertifikasi] = useState('');
  const [extension, setExtension] = useState('');
  const [show_images, setShowImage] = useState('');
  const [golongan, setGolongan] = useState('');
  const { data: session, status } = useSession()


  useEffect(() => {
    setData(props.data)
    props.data ? '' : reset_elm()
  }, [props.data])

  const setData = (values) => {
    if (values[0] != undefined) {
      const value = values[0]
      setSertifikasiId(value.jenis_sertifikasi_id != undefined ? value.jenis_sertifikasi_id : "")
      setNamaSertifikasi(value.nama_sertifikasi)
      setNamaLembaga(value.nama_lembaga)
      setNoSertifikasi(value.no_sertifikasi)
      setFileSertifikasi(value.file_sertifikasi)
      setTglTerbit(moment(value.tgl_terbit).format('YYYY-MM-DD'))
      setMasaBerlaku(moment(value.masa_berlaku).format('YYYY-MM-DD'))
    }
  }
  const reset_elm = () => {
    setSertifikasiId('')
    setTglTerbit('')
    setMasaBerlaku('')
    setGolongan('')
    setNamaLembaga('')
    setNamaSertifikasi('')
    setNoSertifikasi('')
    setFileSertifikasi('')
    setShowImage('')

  }

  const UpdtGambar = (e) => {
    const [file] = e.target.files;
    setShowImage(URL.createObjectURL(file))
    setExtension(file.name.split('.').pop())
    setFileSertifikasi(e.target.files[0])
  }

  const SendSertifikasi = (param, d) => {
    const data = {
      kode_user_master: session?.kode_user_master,
      ctdby: session?.kode_user_master,
    }
    if (param == "jenis_sertifikasi") {
        setSertifikasiId(d)
        data.jenis_sertifikasi_id = d
        data.nama_sertifikasi = nama_sertifikasi
        data.nama_lembaga = nama_lembaga
        data.masa_berlaku = masa_berlaku
        data.no_sertifikasi = no_sertifikasi
        data.file_sertifikasi = file_sertifikasi
        data.tgl_terbit = tgl_terbit
    } else if (param == "nama_sertifikasi") {
        setNamaSertifikasi(d)
        data.jenis_sertifikasi_id = jenis_sertifikasi_id
        data.nama_sertifikasi = d
        data.nama_lembaga = nama_lembaga
        data.masa_berlaku = masa_berlaku
        data.no_sertifikasi = no_sertifikasi
        data.file_sertifikasi = file_sertifikasi
        data.tgl_terbit = tgl_terbit

    }else if (param == "nama_lembaga") {
        setNamaLembaga(d)
        data.jenis_sertifikasi_id = jenis_sertifikasi_id
        data.nama_sertifikasi = nama_sertifikasi
        data.nama_lembaga = d
        data.masa_berlaku = masa_berlaku
        data.no_sertifikasi = no_sertifikasi
        data.file_sertifikasi = file_sertifikasi
        data.tgl_terbit = tgl_terbit

    }else if (param == "masa_berlaku") {
        setMasaBerlaku(d)
        data.jenis_sertifikasi_id = jenis_sertifikasi_id
        data.nama_sertifikasi = nama_sertifikasi
        data.nama_lembaga = nama_lembaga
        data.masa_berlaku = d
        data.no_sertifikasi = no_sertifikasi
        data.file_sertifikasi = file_sertifikasi
        data.tgl_terbit = tgl_terbit

    }else if (param == "tgl_terbit") {
        setTglTerbit(d)
        data.jenis_sertifikasi_id = jenis_sertifikasi_id
        data.nama_sertifikasi = nama_sertifikasi
        data.nama_lembaga = nama_lembaga
        data.masa_berlaku = masa_berlaku
        data.no_sertifikasi = no_sertifikasi
        data.file_sertifikasi = file_sertifikasi
        data.tgl_terbit = d

    }else if (param == "no_sertifikasi") {
        setNoSertifikasi(d)
        data.jenis_sertifikasi_id = jenis_sertifikasi_id
        data.nama_sertifikasi = nama_sertifikasi
        data.nama_lembaga = nama_lembaga
        data.masa_berlaku = masa_berlaku
        data.no_sertifikasi = d
        data.file_sertifikasi = file_sertifikasi
        data.tgl_terbit = tgl_terbit

    }else if (param == "file_sertifikasi") {
        const [file] = d.target.files
        setShowImage(URL.createObjectURL(file))
        setExtension(file.name.split('.').pop())
        setFileSertifikasi(d.target.files[0])
        data.jenis_sertifikasi_id = jenis_sertifikasi_id
        data.nama_sertifikasi = nama_sertifikasi
        data.nama_lembaga = nama_lembaga
        data.masa_berlaku = masa_berlaku
        data.file_sertifikasi = d.target.files[0]
        data.tgl_terbit = tgl_terbit

      }

    console.log(data);
    const { callBack } = props
    callBack(data)
  }

  return (
    <>
      <div className="grid xl:grid-cols-2 md:grid-cols-2 gap-3">
        <DivCustom label="Nrp"
          form={
            <>
              <InputCustom value={session?.id_unique} placeholder="Nama Anggota" readOnly={true} />
            </>
          }
        />
        <DivCustom label="Nama"
          form={
            <InputCustom value={session?.nama} placeholder="Nama Anggota" readOnly={true} />
          } />
      </div>

      <div className="grid xl:grid-cols-2 md:grid-cols-2 gap-3">
        <DivCustom label="Nama Sertifikasi"
          form={
            <InputCustom value={nama_sertifikasi} onChange={e => SendSertifikasi('nama_sertifikasi', e.target.value)} placeholder="Nama Sertifikasi"   />
        } />
        <DivCustom label="Jenis Sertifikasi"
            form={
              <SelectSertifikasi onChange={(e) => SendSertifikasi('jenis_sertifikasi', e.value)} value={jenis_sertifikasi_id} placeholder="Pilih Sertifikasi" />
        } />
        <DivCustom label="No Sertifikasi"
            form={
            <InputCustom value={no_sertifikasi} onChange={e => SendSertifikasi('no_sertifikasi', e.target.value)} placeholder="No Sertifikasi"  />
        } />
       <DivCustom label="Nama Lembaga"
            form={
            <InputCustom value={nama_lembaga} onChange={e => SendSertifikasi('nama_lembaga', e.target.value)} placeholder="Nama Lembaga"  />
        } />
         <DivCustom label="Tanggal Terbit"
            form={
              <DatePicker className='w-full' value={tgl_terbit ? moment(tgl_terbit, 'YYYY-MM-DD') : ""} onChange={(e, d) => SendSertifikasi('tgl_terbit', d)} picker="date" />
          } />  
        <DivCustom label="Masa Berlaku"
            form={
              <DatePicker className='w-full' value={masa_berlaku ? moment(masa_berlaku, 'YYYY-MM-DD') : ""} onChange={(e, d) => SendSertifikasi('masa_berlaku', d)} picker="date" />
          } />      
       
        <DivCustom label="File Sertifikasi"
            form={
              <div>
                <input type="file" className='w-[88px]' onChange={e => SendSertifikasi('file_sertifikasi',e)} />
                {/* <input type="file" className='w-[88px]' onChange={e => UpdtGambar(e)} /> */}
                <div className='p-2'>
                  {
                    typeof file_sertifikasi == "object" ? <p className='mb-4'>Selected file :<b> {file_sertifikasi.name}</b></p> : <></>
                  }
                  {extension == "png" || extension == "jpg" || extension == "jpeg" ? (<>
                    <Image
                      loader={e => { return show_images ? show_images : "/images/image_select.png" }}
                      src={show_images ? show_images : "/images/image_select.png"}
                      alt="image icon"
                      width={200}
                      height={200} />
                  </>) : (<></>)}
                  <p className='text-[12px] text-justify -mb-1' >* Jika dokumentasi lebih dari satu foto, upload dalam bentuk pdf</p>
                  <p className='text-[12px] text-justify -mb-1' >* File maksimal <span className="text-red-500">2 Mb</span></p>
                  <p className='text-[12px] text-justify' >* Format file <span className="text-red-500 uppercase">png,jpg,jpeg,pdf</span></p>
                </div>
                 {
                        typeof file_kontrak_kerja == "string" ? (<><p className='text-[12px] text-justify' >Lihat file awal <span className="text-red-500 uppercase"> <a href={`${file_sertifikasi}`} target="_blank" rel="noopener noreferrer">Lihat File</a> </span></p></>) : (<></>)
                      }
              </div>
          } />
      </div>
      <div className="grid xl:grid-cols-2 md:grid-cols-2 gap-3">
  
      </div>

    </>
  )
}

export function FormPangkatDanSotk(props) {
  const [saat_ini, setSaatIni] = useState(false);
  const [rowid, setRowId] = useState('');
  const [pangkat_id, setPangkatId] = useState('');
  const [jabatan_id, setJabatanId] = useState('');
  const [tgl_berakhir, setTglBerakhir] = useState(''); 
  const [sotk_id, setSotkId] = useState('');
  const [fungsi_kepolisian_id, setFungsiKepolisianId] = useState('');
  const [sub_fungsi_kepolisian_id, setSubFungsiKepolisianId] = useState('');  const [kesatuan, setKesatuan] = useState('');
  const [tgl_ditetapkan, setTglDitetapkan] = useState('');
  const [polda_id, setPoldaId] = useState('');
  const [polres_id, setPolresId] = useState('');
  const [loading, setLoading] = useState(false);
  const { data: session, status } = useSession()

  useEffect(() => {
    setData(props.data)
    props.data ? '' : reset_elm()
  }, [props.data])


  const setData = (values) => {
    if (values[0] != undefined) {
      const value = values[0]
      setPangkatId(value.pangkat_id != undefined ? value.pangkat_id : "")
      setJabatanId(value.jabatan_id != undefined ? value.jabatan_id : "")
      setSotkId(value.sotk_id != undefined ? value.sotk_id : "")
      setPoldaId(value.polda_id)
      setPolresId(value.polres_id)
      setKesatuan(value.kesatuan)
      setFungsiKepolisianId(value.fungsi_kepolisian_id)
      setSubFungsiKepolisianId(value.sub_fungsi_kepolisian_id)
      setTglDitetapkan(moment(value.tgl_ditetapkan).format('YYYY-MM-DD'))
      setTglBerakhir(moment(value.tgl_berakhir).format('YYYY-MM-DD'))
      setSaatIni(value.saat_ini)
    }
  }

  const reset_elm = () => {
    setLoading()
    setPangkatId('')
    setJabatanId('')
    setSotkId('')
    setTglDitetapkan('')
    setTglBerakhir('')
    setPoldaId('')
    setKesatuan('')
    setPolresId('')
    setFungsiKepolisianId('')
    setSubFungsiKepolisianId('')
    setSotkId('')
    setSaatIni(false)
  }

  const SendPangDanSotk = (param, d) => {
    var data = {
      kode_user_master: session?.kode_user_master,
      polda_id: polda_id,
      polres_id: polres_id,
      ctdby: session?.kode_user_master,
    }

    if (param == "saat_ini") {
      d ? setSaatIni(d) : ""
      data.saat_ini = d
      data.tgl_ditetapkan = tgl_ditetapkan
      data.tgl_berakhir = tgl_berakhir
      data.pangkat_id = pangkat_id
      data.jabatan_id = jabatan_id
      data.sotk_id = sotk_id
      

    } else if (param == "tgl_ditetapkan") {
      d ? setTglDitetapkan(d) : ""
      data.saat_ini = saat_ini
      data.tgl_ditetapkan = d
      data.tgl_berakhir = tgl_berakhir
      pangkat_id ? data.pangkat_id = pangkat_id :  jabatan_id ? data.jabatan_id = jabatan_id : data.sotk_id = sotk_id

    } else if (param == "tgl_berakhir") {
      d ? setTglBerakhir(d) : ""
      data.saat_ini = saat_ini
      data.tgl_ditetapkan = tgl_ditetapkan
      data.tgl_berakhir = d
      pangkat_id ? data.pangkat_id = pangkat_id :  jabatan_id ? data.jabatan_id = jabatan_id : data.sotk_id = sotk_id

    } else if (param == "pangkat") {
      d ? setPangkatId(d) : ""
      data.pangkat_id = d
      data.saat_ini = saat_ini
      data.tgl_ditetapkan = tgl_ditetapkan
      data.tgl_berakhir = tgl_berakhir

    }else if (param == "jabatan") {
      d ? setJabatanId(d) : ""
      data.jabatan_id = d
      data.fungsi_kepolisian_id = fungsi_kepolisian_id
      data.sub_fungsi_kepolisian_id = sub_fungsi_kepolisian_id
      data.sotk_id = sotk_id
      data.saat_ini = saat_ini
      data.tgl_ditetapkan = tgl_ditetapkan
      data.tgl_berakhir = tgl_berakhir

    } else if (param == "sotk") {
      d ? setSotkId(d) : ""
      data.sotk_id = d
      data.fungsi_kepolisian_id = fungsi_kepolisian_id
      data.sub_fungsi_kepolisian_id = sub_fungsi_kepolisian_id
      data.saat_ini = saat_ini
      data.tgl_ditetapkan = tgl_ditetapkan
      data.tgl_berakhir = tgl_berakhir

    } else if (param == "fungsi_kepolisian") {
      d ? setFungsiKepolisianId(d) : ""
      data.fungsi_kepolisian_id = d
      data.sub_fungsi_kepolisian_id = sub_fungsi_kepolisian_id      
      data.saat_ini = saat_ini
      data.tgl_ditetapkan = tgl_ditetapkan
      data.tgl_berakhir = tgl_berakhir

    } else if (param == "sub_fungsi_kepolisian") {
      d ? setSubFungsiKepolisianId(d) : ""
      data.fungsi_kepolisian_id = fungsi_kepolisian_id
      data.sub_fungsi_kepolisian_id = d      
      data.saat_ini = saat_ini
      data.tgl_ditetapkan = tgl_ditetapkan
      data.tgl_berakhir = tgl_berakhir

    } else if (param == "polres") {
      d ? setPolresId(d) : ""
      data.polres_id = d
      data.fungsi_kepolisian_id = fungsi_kepolisian_id
      data.sub_fungsi_kepolisian_id = sub_fungsi_kepolisian_id
      data.saat_ini = saat_ini
      data.tgl_ditetapkan = tgl_ditetapkan
      data.tgl_berakhir = tgl_berakhir
      data.polda_id = polda_id
      pangkat_id ? data.pangkat_id = pangkat_id :  jabatan_id ? data.jabatan_id = jabatan_id : data.sotk_id = sotk_id

    } else if (param == "polda") {
      d ? setPoldaId(d) : ""
      data.polda_id = d
      data.fungsi_kepolisian_id = fungsi_kepolisian_id
      data.sub_fungsi_kepolisian_id = sub_fungsi_kepolisian_id
      data.saat_ini = saat_ini
      data.tgl_ditetapkan = tgl_ditetapkan
      data.tgl_berakhir = tgl_berakhir
      data.polres_id = polres_id
      pangkat_id ? data.pangkat_id = pangkat_id :  jabatan_id ? data.jabatan_id = jabatan_id : data.sotk_id = sotk_id
    }
    const { callBack } = props
    callBack(data)
  }

  return (
    <>
      <div className="grid xl:grid-cols-2 md:grid-cols-2 gap-3">
        <DivCustom label="Nrp"
          form={
            <>
              <InputCustom value={session?.id_unique} placeholder="Nama Anggota" readOnly={true} />
            </>
          }
        />
        <DivCustom label="Nama"
          form={
            <InputCustom value={session?.nama} placeholder="Nama Anggota" readOnly={true} />
          } />
      </div>
      <div className="grid xl:grid-cols-2 md:grid-cols-2 gap-3">
       <DivCustom label="Hirarki"
              form={
                <SelectCustom onChange={(e) => setKesatuan(e.value)} value={kesatuan} placeholder="Pilih Kesatuan">
                  {
                    [
                      { label: 'MABES', value: 'MABES' },
                      { label: 'POLDA', value: 'POLDA' },
                      { label: 'POLRES', value: 'POLRES' },
                    ]
                  }
                </SelectCustom>
              }
            />
        {kesatuan == "POLDA" || kesatuan == "POLRES" ? (<>    
        <DivCustom label="Polda"
          form={
            <SelectPolda onChange={(e) => SendPangDanSotk('polda', e.value)} value={polda_id} placeholder="Pilih Polda" />
          } />
        </>) : ""}
        {kesatuan == "POLRES" ? (<>
        <DivCustom label="Polres"
          form={
            <SelectPolres onChange={(e) => SendPangDanSotk('polres', e.value)} value={polres_id} param={polda_id} placeholder="Pilih Polres" />
          } />
          </>) : ""}
          
        {props.param == "jabatan" ? (<>
         <DivCustom label="Fungsi Kepolisian"
            form={
              <SelectFungsiKepolisian onChange={(e) => SendPangDanSotk('fungsi_kepolisian', e.value)} value={fungsi_kepolisian_id} placeholder="Pilih Fungsi Kepolisian" param={kesatuan}  />
          } />
          <DivCustom label="SubFungsi Kepolisian"
            form={
              <SelectSubFungsiKepolisian onChange={(e) => SendPangDanSotk('sub_fungsi_kepolisian', e.value)} value={sub_fungsi_kepolisian_id} placeholder="Pilih Sub Fungsi Kepolisian" param={fungsi_kepolisian_id}  />
          } />
          <DivCustom label="Kedinasan"
            form={
              <SelectSotk onChange={(e) => SendPangDanSotk('sotk', e.value)} value={sotk_id} placeholder="Pilih Kedinasan" param={sub_fungsi_kepolisian_id} golongan={"KEDINASAN"} />
          } />
          <DivCustom label="Jabatan"
            form={
              <SelectJabatan onChange={(e) => SendPangDanSotk('jabatan', e.value)} value={jabatan_id} placeholder="Pilih Jabatan" param={sotk_id} />
            } />
        </>)  : (<>
        <DivCustom label="Fungsi Kepolisian"
            form={
              <SelectFungsiKepolisian onChange={(e) => SendPangDanSotk('fungsi_kepolisian', e.value)} value={fungsi_kepolisian_id} placeholder="Pilih Fungsi Kepolisian" param={kesatuan}  />
          } />
          <DivCustom label="SubFungsi Kepolisian"
            form={
              <SelectSubFungsiKepolisian onChange={(e) => SendPangDanSotk('sub_fungsi_kepolisian', e.value)} value={sub_fungsi_kepolisian_id} placeholder="Pilih Sub Fungsi Kepolisian" param={fungsi_kepolisian_id}  />
          } />
        <DivCustom label="Kedinasan"
            form={
              <SelectSotk onChange={(e) => SendPangDanSotk('sotk', e.value)} value={sotk_id} placeholder="Pilih Kedinasan" param={sub_fungsi_kepolisian_id} golongan={"KEDINASAN"} />
          } /></>)}
        {session?.kode_jenis_user == "EXTERNAL" ?(<>
          <DivCustom label="Satuan Kerja"
            form={
              <SelectSotk onChange={(e) => SendPangDanSotk('sotk', e.value)} value={sotk_id} placeholder="Pilih Satuan Kerja" param={kesatuan} golongan={"SATUAN KERJA"} />
          } />
        </>):(<></>)}
        <DivCustom label="Tanggal Ditetapkan"
          form={
            <DatePicker className='w-full' value={tgl_ditetapkan ? moment(tgl_ditetapkan, 'YYYY-MM-DD') : ""} onChange={(e, d) => SendPangDanSotk('tgl_ditetapkan', d)} picker="date" />
          } />
        <DivCustom label="Tanggal Berakhir"
          form={
            <DatePicker className='w-full' value={tgl_berakhir ? moment(tgl_berakhir, 'YYYY-MM-DD') : ""} onChange={(e, d) => SendPangDanSotk('tgl_berakhir', d)} picker="date" />
        } />

      </div>
      {props.param == "jabatan" ? (<>
      <div className='flex'>
        <input type={"checkbox"} onChange={e => SendPangDanSotk('saat_ini', !saat_ini)} checked={saat_ini} /> <span className='ml-2'>Jabatan Saat ini</span>
      </div>
       </>)  :  (<>
       <div className='flex'>
        <input type={"checkbox"} onChange={e => SendPangDanSotk('saat_ini', !saat_ini)} checked={saat_ini} /> <span className='ml-2'>Kedinasan Saat ini</span>
      </div>
       </>)}

    </>
  )
}

export function FormPangkat(props) {
  const [saat_ini, setSaatIni] = useState(false);
  const [rowid, setRowId] = useState('');
  const [pangkat_id, setPangkatId] = useState('');
  const [tgl_berakhir, setTglBerakhir] = useState('');  
  const [kesatuan, setKesatuan] = useState('');
  const [tgl_ditetapkan, setTglDitetapkan] = useState('');
  const [polda_id, setPoldaId] = useState('');
  const [polres_id, setPolresId] = useState('');
  const [loading, setLoading] = useState(false);
  const { data: session, status } = useSession()

  useEffect(() => {
    setData(props.data)
    props.data ? '' : reset_elm()
  }, [props.data])


  const setData = (values) => {
    if (values[0] != undefined) {
      const value = values[0]
      setPangkatId(value.pangkat_id != undefined ? value.pangkat_id : "")
      setPoldaId(value.polda_id)
      setPolresId(value.polres_id)
      setKesatuan(value.kesatuan)
      setTglDitetapkan(moment(value.tgl_ditetapkan).format('YYYY-MM-DD'))
      setTglBerakhir(moment(value.tgl_berakhir).format('YYYY-MM-DD'))
      setSaatIni(value.saat_ini)
    }
  }

  const reset_elm = () => {
    setLoading()
    setPangkatId('')
    setTglDitetapkan('')
    setTglBerakhir('')
    setPoldaId('')
    setKesatuan('')
    setPolresId('')
    setSaatIni(false)
  }

  const SendPangkat = (param, d) => {
    var data = {
      kode_user_master: session?.kode_user_master,
      polda_id: polda_id,
      polres_id: polres_id,
      ctdby: session?.kode_user_master,
    }

    if (param == "saat_ini") {
      d ? setSaatIni(d) : ""
      data.saat_ini = d
      data.tgl_ditetapkan = tgl_ditetapkan
      data.tgl_berakhir = tgl_berakhir
      data.pangkat_id = pangkat_id

    } else if (param == "tgl_ditetapkan") {
      d ? setTglDitetapkan(d) : ""
      data.saat_ini = saat_ini
      data.tgl_ditetapkan = d
      data.tgl_berakhir = tgl_berakhir
      data.pangkat_id = pangkat_id

    } else if (param == "tgl_berakhir") {
      d ? setTglBerakhir(d) : ""
      data.saat_ini = saat_ini
      data.tgl_ditetapkan = tgl_ditetapkan
      data.tgl_berakhir = d
      data.pangkat_id = pangkat_id

    } else if (param == "pangkat") {
      d ? setPangkatId(d) : ""
      data.pangkat_id = d
      data.saat_ini = saat_ini
      data.tgl_ditetapkan = tgl_ditetapkan
      data.tgl_berakhir = tgl_berakhir

    } else if (param == "polres") {
      d ? setPolresId(d) : ""
      data.polres_id = d
      data.saat_ini = saat_ini
      data.tgl_ditetapkan = tgl_ditetapkan
      data.tgl_berakhir = tgl_berakhir
      data.polda_id = polda_id
      data.pangkat_id = pangkat_id

    } else if (param == "polda") {
      d ? setPoldaId(d) : ""
      data.polda_id = d
      data.saat_ini = saat_ini
      data.tgl_ditetapkan = tgl_ditetapkan
      data.tgl_berakhir = tgl_berakhir
      data.polres_id = polres_id
      data.pangkat_id = pangkat_id
    }
    const { callBack } = props
    console.log(data);
    callBack(data)
  }

  return (
    <>
      <div className="grid xl:grid-cols-2 md:grid-cols-2 gap-3">
        <DivCustom label="Nrp"
          form={
            <>
              <InputCustom value={session?.id_unique} placeholder="Nama Anggota" readOnly={true} />
            </>
          }
        />
        <DivCustom label="Nama"
          form={
            <InputCustom value={session?.nama} placeholder="Nama Anggota" readOnly={true} />
          } />
      </div>
      <div className="grid xl:grid-cols-2 md:grid-cols-2 gap-3">
       <DivCustom label="Hirarki"
              form={
                <SelectCustom onChange={(e) => setKesatuan(e.value)} value={kesatuan} placeholder="Pilih Kesatuan">
                  {
                    [
                      { label: 'MABES', value: 'MABES' },
                      { label: 'POLDA', value: 'POLDA' },
                      { label: 'POLRES', value: 'POLRES' },
                    ]
                  }
                </SelectCustom>
              }
            />
        {kesatuan == "POLDA" || kesatuan == "POLRES" ? (<>    
        <DivCustom label="Polda"
          form={
            <SelectPolda onChange={(e) => SendPangkat('polda', e.value)} value={polda_id} placeholder="Pilih Polda" />
          } />
        </>) : ""}
        {kesatuan == "POLRES" ? (<>
        <DivCustom label="Polres"
          form={
            <SelectPolres onChange={(e) => SendPangkat('polres', e.value)} value={polres_id} param={polda_id} placeholder="Pilih Polres" />
          } />
          </>) : ""}
          
        <DivCustom label="Pangkat"
          form={
            <SelectPangkat onChange={(e) => SendPangkat('pangkat', e.value)} value={pangkat_id} placeholder="Pilih Pangkat" />
        } />
        <DivCustom label="Tanggal Ditetapkan"
          form={
            <DatePicker className='w-full' value={tgl_ditetapkan ? moment(tgl_ditetapkan, 'YYYY-MM-DD') : ""} onChange={(e, d) => SendPangkat('tgl_ditetapkan', d)} picker="date" />
          } />
        <DivCustom label="Tanggal Berakhir"
          form={
            <DatePicker className='w-full' value={tgl_berakhir ? moment(tgl_berakhir, 'YYYY-MM-DD') : ""} onChange={(e, d) => SendPangkat('tgl_berakhir', d)} picker="date" />
        } />

      </div>
       <div className="grid xl:grid-cols-2 md:grid-cols-2 gap-3">
        <div className='flex'>
                      <input type={"checkbox"} onChange={e => SendPangkat('saat_ini', !saat_ini)} checked={saat_ini} /> <span className='ml-2'>Pangkat anda saat ini</span>

          {/* <input type={"checkbox"} onChange={e => SendPangkat('saat_ini', !saat_ini)} checked={saat_ini} /> <span className='ml-2'>Pangkat Saat ini</span> */}
        </div>
      </div>

    </>
  )
}

export function FormJabatan(props) {
  const [saat_ini, setSaatIni] = useState(false);
  const [rowid, setRowId] = useState('');
  const [sotk_id, setSotkId] = useState('');
  const [jabatan_id, setJabatanId] = useState('');
  const [tgl_berakhir, setTglBerakhir] = useState('');  
  const [kesatuan, setKesatuan] = useState('');
  const [tgl_ditetapkan, setTglDitetapkan] = useState('');
  const [fungsi_kepolisian_id, setFungsiKepolisianId] = useState('');
  const [sub_fungsi_kepolisian_id, setSubFungsiKepolisianId] = useState(''); 
  const [polda_id, setPoldaId] = useState('');
  const [polres_id, setPolresId] = useState('');
  const [golongan, setGolongan] = useState('');
  const [loading, setLoading] = useState(false);
  const { data: session, status } = useSession()

  useEffect(() => {
    setData(props.data)
    props.data ? '' : reset_elm()
  }, [props.data])


  const setData = (values) => {
    if (values[0] != undefined) {
      const value = values[0]
      setJabatanId(value.jabatan_id != undefined ? value.jabatan_id : "")
      setPoldaId(value.polda_id)
      setPolresId(value.polres_id)
      setKesatuan(value.kesatuan)
      setTglDitetapkan(moment(value.tgl_ditetapkan).format('YYYY-MM-DD'))
      setTglBerakhir(moment(value.tgl_berakhir).format('YYYY-MM-DD'))
      setSaatIni(value.saat_ini)
    }
  }

  const reset_elm = () => {
    setLoading()
    setJabatanId('')
    setTglDitetapkan('')
    setTglBerakhir('')
    setPoldaId('')
    setKesatuan('')
    setPolresId('')
    setGolongan('')
    setSaatIni(false)
  }

  const SendJabatan = (param, d) => {
    var data = {
      kode_user_master: session?.kode_user_master,
      polda_id: polda_id,
      polres_id: polres_id,
      ctdby: session?.kode_user_master,
      
    }

    if (param == "saat_ini") {
      d ? setSaatIni(d) : ""
      data.saat_ini = d
      data.tgl_ditetapkan = tgl_ditetapkan
      data.tgl_berakhir = tgl_berakhir
      data.fungsi_kepolisian_id = fungsi_kepolisian_id
      data.sub_fungsi_kepolisian_id = sub_fungsi_kepolisian_id
      data.polda_id = polda_id
      data.polres_id = polres_id
      data.jabatan_id = jabatan_id
      data.sotk_id = sotk_id

    } else if (param == "tgl_ditetapkan") {
      d ? setTglDitetapkan(d) : ""
      data.saat_ini = saat_ini
      data.tgl_ditetapkan = d
      data.tgl_berakhir = tgl_berakhir
      data.fungsi_kepolisian_id = fungsi_kepolisian_id
      data.sub_fungsi_kepolisian_id = sub_fungsi_kepolisian_id
      data.polda_id = polda_id
      data.polres_id = polres_id
      data.jabatan_id = jabatan_id
      data.sotk_id = sotk_id

    } else if (param == "tgl_berakhir") {
      d ? setTglBerakhir(d) : ""
      data.saat_ini = saat_ini
      data.tgl_ditetapkan = tgl_ditetapkan
      data.tgl_berakhir = d
      data.fungsi_kepolisian_id = fungsi_kepolisian_id
      data.sub_fungsi_kepolisian_id = sub_fungsi_kepolisian_id
      data.polda_id = polda_id
      data.polres_id = polres_id
      data.jabatan_id = jabatan_id
      data.sotk_id = sotk_id


    } else if (param == "jabatan") {
      d ? setJabatanId(d) : ""
      data.jabatan_id = d
      data.saat_ini = saat_ini
      data.tgl_ditetapkan = tgl_ditetapkan
      data.tgl_berakhir = tgl_berakhir
      data.fungsi_kepolisian_id = fungsi_kepolisian_id
      data.sub_fungsi_kepolisian_id = sub_fungsi_kepolisian_id
      data.polda_id = polda_id
      data.polres_id = polres_id
      data.sotk_id = sotk_id

    } else if (param == "polres") {
      d ? setPolresId(d) : ""
      data.polres_id = d
      data.saat_ini = saat_ini
      data.tgl_ditetapkan = tgl_ditetapkan
      data.tgl_berakhir = tgl_berakhir
      data.polda_id = polda_id
      data.fungsi_kepolisian_id = fungsi_kepolisian_id
      data.sub_fungsi_kepolisian_id = sub_fungsi_kepolisian_id
      data.polda_id = polda_id
      data.polres_id = polres_id
      data.jabatan_id = jabatan_id
      data.sotk_id = sotk_id

    } else if (param == "polda") {
      d ? setPoldaId(d) : ""
      data.polda_id = d
      data.saat_ini = saat_ini
      data.tgl_ditetapkan = tgl_ditetapkan
      data.tgl_berakhir = tgl_berakhir
      data.polres_id = polres_id
      data.fungsi_kepolisian_id = fungsi_kepolisian_id
      data.sub_fungsi_kepolisian_id = sub_fungsi_kepolisian_id
      data.polda_id = polda_id
      data.polres_id = polres_id
      data.jabatan_id = jabatan_id
      data.sotk_id = sotk_id
     
    } else if (param == "sotk") {
      d ? setSotkId(d) : ""
      data.sotk_id = d
      data.fungsi_kepolisian_id = fungsi_kepolisian_id
      data.sub_fungsi_kepolisian_id = sub_fungsi_kepolisian_id
      data.saat_ini = saat_ini
      data.tgl_ditetapkan = tgl_ditetapkan
      data.tgl_berakhir = tgl_berakhir
      data.polda_id = polda_id
      data.polres_id = polres_id
      data.jabatan_id = jabatan_id

     }else if (param == "fungsi_kepolisian") {
      d ? setFungsiKepolisianId(d) : ""
      data.fungsi_kepolisian_id = d
      data.sub_fungsi_kepolisian_id = sub_fungsi_kepolisian_id      
      data.saat_ini = saat_ini
      data.tgl_ditetapkan = tgl_ditetapkan
      data.tgl_berakhir = tgl_berakhir
      data.polda_id = polda_id
      data.polres_id = polres_id
      data.jabatan_id = jabatan_id
      data.sotk_id = sotk_id

    } else if (param == "sub_fungsi_kepolisian") {
      d ? setSubFungsiKepolisianId(d) : ""
      data.fungsi_kepolisian_id = fungsi_kepolisian_id
      data.sub_fungsi_kepolisian_id = d      
      data.saat_ini = saat_ini
      data.tgl_ditetapkan = tgl_ditetapkan
      data.tgl_berakhir = tgl_berakhir
      data.polda_id = polda_id
      data.polres_id = polres_id
      data.jabatan_id = jabatan_id
      data.sotk_id = sotk_id
    }
    
    const { callBack } = props
    console.log(data);
    callBack(data)
  }

  return (
    <>
      <div className="grid xl:grid-cols-2 md:grid-cols-2 gap-3">
        <DivCustom label="Nrp"
          form={
            <>
              <InputCustom value={session?.id_unique} placeholder="Nama Anggota" readOnly={true} />
            </>
          }
        />
        <DivCustom label="Nama"
          form={
            <InputCustom value={session?.nama} placeholder="Nama Anggota" readOnly={true} />
          } />
      </div>
      <div className="grid xl:grid-cols-2 md:grid-cols-2 gap-3">
       <DivCustom label="Hirarki"
              form={
                <SelectCustom onChange={(e) => setKesatuan(e.value)} value={kesatuan} placeholder="Pilih Kesatuan">
                  {
                    [
                      { label: 'MABES', value: 'MABES' },
                      { label: 'POLDA', value: 'POLDA' },
                      { label: 'POLRES', value: 'POLRES' },
                    ]
                  }
                </SelectCustom>
              }
            />
        {kesatuan == "POLDA" || kesatuan == "POLRES" ? (<>    
        <DivCustom label="Polda"
          form={
            <SelectPolda onChange={(e) => SendJabatan('polda', e.value)} value={polda_id} placeholder="Pilih Polda" />
          } />
        </>) : ""}
        {kesatuan == "POLRES" ? (<>
        <DivCustom label="Polres"
          form={
            <SelectPolres onChange={(e) => SendJabatan('polres', e.value)} value={polres_id} param={polda_id} placeholder="Pilih Polres" />
          } />
          </>) : ""}

        <DivCustom label="Fungsi Kepolisian"
            form={
              <SelectFungsiKepolisian onChange={(e) => SendJabatan('fungsi_kepolisian', e.value)} value={fungsi_kepolisian_id} placeholder="Pilih Fungsi Kepolisian" param={kesatuan}  />
          } />
          <DivCustom label="SubFungsi Kepolisian"
            form={
              <SelectSubFungsiKepolisian onChange={(e) => SendJabatan('sub_fungsi_kepolisian', e.value)} value={sub_fungsi_kepolisian_id} placeholder="Pilih Sub Fungsi Kepolisian" param={fungsi_kepolisian_id}  />
          } />

         <DivCustom label="Kedinasan"
            form={
              <SelectSotk onChange={(e) => SendJabatan('sotk', e.value)} value={sotk_id} placeholder="Pilih Kedinasan" param={sub_fungsi_kepolisian_id} golongan={"KEDINASAN"} />
          } />
          
        <DivCustom label="Jabatan"
          form={
            <SelectJabatan onChange={(e) => SendJabatan('jabatan', e.value)} value={jabatan_id} placeholder="Pilih Jabatan" param={sotk_id} />
        } />
        
        <DivCustom label="Tanggal Ditetapkan"
          form={
            <DatePicker className='w-full' value={tgl_ditetapkan ? moment(tgl_ditetapkan, 'YYYY-MM-DD') : ""} onChange={(e, d) => SendJabatan('tgl_ditetapkan', d)} picker="date" />
          } />
        <DivCustom label="Tanggal Berakhir"
          form={
            <DatePicker className='w-full' value={tgl_berakhir ? moment(tgl_berakhir, 'YYYY-MM-DD') : ""} onChange={(e, d) => SendJabatan('tgl_berakhir', d)} picker="date" />
        } />

      </div>
       <div className="grid xl:grid-cols-2 md:grid-cols-2 gap-3">
        <div className='flex'>
          <input type={"checkbox"} onChange={e => SendJabatan('saat_ini', !saat_ini)} checked={saat_ini} /> <span className='ml-2'>Jabatan Saat ini</span>
        </div>
      </div>

    </>
  )
}

export function FormKedinasan(props) {
  const [saat_ini, setSaatIni] = useState(false);
  const [rowid, setRowId] = useState('');
  const [sotk_id, setSotkId] = useState('');
  const [tgl_berakhir, setTglBerakhir] = useState('');  
  const [kesatuan, setKesatuan] = useState('');
  const [tgl_ditetapkan, setTglDitetapkan] = useState('');
  const [fungsi_kepolisian_id, setFungsiKepolisianId] = useState('');
  const [sub_fungsi_kepolisian_id, setSubFungsiKepolisianId] = useState(''); 
  const [polda_id, setPoldaId] = useState('');
  const [polres_id, setPolresId] = useState('');
  const [golongan, setGolongan] = useState('');
  const [loading, setLoading] = useState(false);
  const { data: session, status } = useSession()

  useEffect(() => {
    setData(props.data)
    props.data ? '' : reset_elm()
  }, [props.data])


  const setData = (values) => {
    if (values[0] != undefined) {
      
      const value = values[0]
      setSotkId(value.sotk_id != undefined ? value.sotk_id : "")
      setPoldaId(value.polda_id)
      setPolresId(value.polres_id)
      setFungsiKepolisianId(value.fungsi_kepolisian_id)
      setSubFungsiKepolisianId(value.sub_fungsi_kepolisian_id)
      setKesatuan(value.kesatuan)
      setTglDitetapkan(moment(value.tgl_ditetapkan).format('YYYY-MM-DD'))
      setTglBerakhir(moment(value.tgl_berakhir).format('YYYY-MM-DD'))
      setSaatIni(value.saat_ini)
    }
  }

  const reset_elm = () => {
    setLoading()
    setSotkId('')
    setTglDitetapkan('')
    setTglBerakhir('')
    setPoldaId('')
    setKesatuan('')
    setPolresId('')
    setGolongan('')
    setSaatIni(false)
  }

  const SendKedinasan = (param, d) => {
    var data = {
      kode_user_master: session?.kode_user_master,
      polda_id: polda_id,
      polres_id: polres_id,
      ctdby: session?.kode_user_master,
      
    }

    if (param == "saat_ini") {
      d ? setSaatIni(d) : ""
      data.saat_ini = d
      data.tgl_ditetapkan = tgl_ditetapkan
      data.tgl_berakhir = tgl_berakhir
      data.fungsi_kepolisian_id = fungsi_kepolisian_id
      data.sub_fungsi_kepolisian_id = sub_fungsi_kepolisian_id
      data.polda_id = polda_id
      data.polres_id = polres_id
      data.sotk_id = sotk_id

    } else if (param == "tgl_ditetapkan") {
      d ? setTglDitetapkan(d) : ""
      data.saat_ini = saat_ini
      data.tgl_ditetapkan = d
      data.tgl_berakhir = tgl_berakhir
      data.fungsi_kepolisian_id = fungsi_kepolisian_id
      data.sub_fungsi_kepolisian_id = sub_fungsi_kepolisian_id
      data.polda_id = polda_id
      data.polres_id = polres_id
      data.sotk_id = sotk_id

    } else if (param == "tgl_berakhir") {
      d ? setTglBerakhir(d) : ""
      data.saat_ini = saat_ini
      data.tgl_ditetapkan = tgl_ditetapkan
      data.tgl_berakhir = d
      data.fungsi_kepolisian_id = fungsi_kepolisian_id
      data.sub_fungsi_kepolisian_id = sub_fungsi_kepolisian_id
      data.polda_id = polda_id
      data.polres_id = polres_id
      data.sotk_id = sotk_id


    } else if (param == "sotk") {
      d ? setSotkId(d) : ""
      data.saat_ini = saat_ini
      data.tgl_ditetapkan = tgl_ditetapkan
      data.tgl_berakhir = tgl_berakhir
      data.fungsi_kepolisian_id = fungsi_kepolisian_id
      data.sub_fungsi_kepolisian_id = sub_fungsi_kepolisian_id
      data.polda_id = polda_id
      data.polres_id = polres_id
      data.sotk_id = d

    } else if (param == "polres") {
      d ? setPolresId(d) : ""
      data.polres_id = d
      data.saat_ini = saat_ini
      data.tgl_ditetapkan = tgl_ditetapkan
      data.tgl_berakhir = tgl_berakhir
      data.polda_id = polda_id
      data.fungsi_kepolisian_id = fungsi_kepolisian_id
      data.sub_fungsi_kepolisian_id = sub_fungsi_kepolisian_id
      data.polda_id = polda_id
      data.polres_id = polres_id
      data.sotk_id = sotk_id

    } else if (param == "polda") {
      d ? setPoldaId(d) : ""
      data.polda_id = d
      data.saat_ini = saat_ini
      data.tgl_ditetapkan = tgl_ditetapkan
      data.tgl_berakhir = tgl_berakhir
      data.polres_id = polres_id
      data.fungsi_kepolisian_id = fungsi_kepolisian_id
      data.sub_fungsi_kepolisian_id = sub_fungsi_kepolisian_id
      data.polda_id = polda_id
      data.polres_id = polres_id
      data.sotk_id = sotk_id
     
    } else if (param == "fungsi_kepolisian") {
      d ? setFungsiKepolisianId(d) : ""
      data.fungsi_kepolisian_id = d
      data.sub_fungsi_kepolisian_id = sub_fungsi_kepolisian_id      
      data.saat_ini = saat_ini
      data.tgl_ditetapkan = tgl_ditetapkan
      data.tgl_berakhir = tgl_berakhir
      data.polda_id = polda_id
      data.polres_id = polres_id
      data.sotk_id = sotk_id

    } else if (param == "sub_fungsi_kepolisian") {
      d ? setSubFungsiKepolisianId(d) : ""
      data.fungsi_kepolisian_id = fungsi_kepolisian_id
      data.sub_fungsi_kepolisian_id = d      
      data.saat_ini = saat_ini
      data.tgl_ditetapkan = tgl_ditetapkan
      data.tgl_berakhir = tgl_berakhir
      data.polda_id = polda_id
      data.polres_id = polres_id
      data.sotk_id = sotk_id
    }
    
    const { callBack } = props
    console.log(data);
    callBack(data)
  }

  return (
    <>
      <div className="grid xl:grid-cols-2 md:grid-cols-2 gap-3">
        <DivCustom label="Nrp"
          form={
            <>
              <InputCustom value={session?.id_unique} placeholder="Nama Anggota" readOnly={true} />
            </>
          }
        />
        <DivCustom label="Nama"
          form={
            <InputCustom value={session?.nama} placeholder="Nama Anggota" readOnly={true} />
          } />
      </div>
      <div className="grid xl:grid-cols-2 md:grid-cols-2 gap-3">
       <DivCustom label="Hirarki"
              form={
                <SelectCustom onChange={(e) => setKesatuan(e.value)} value={kesatuan} placeholder="Pilih Kesatuan">
                  {
                    [
                      { label: 'MABES', value: 'MABES' },
                      { label: 'POLDA', value: 'POLDA' },
                      { label: 'POLRES', value: 'POLRES' },
                    ]
                  }
                </SelectCustom>
              }
            />
        {kesatuan == "POLDA" || kesatuan == "POLRES" ? (<>    
        <DivCustom label="Polda"
          form={
            <SelectPolda onChange={(e) => SendKedinasan('polda', e.value)} value={polda_id} placeholder="Pilih Polda" />
          } />
        </>) : ""}
        {kesatuan == "POLRES" ? (<>
        <DivCustom label="Polres"
          form={
            <SelectPolres onChange={(e) => SendKedinasan('polres', e.value)} value={polres_id} param={polda_id} placeholder="Pilih Polres" />
          } />
          </>) : ""}

        <DivCustom label="Fungsi Kepolisian"
            form={
              <SelectFungsiKepolisian onChange={(e) => SendKedinasan('fungsi_kepolisian', e.value)} value={fungsi_kepolisian_id} placeholder="Pilih Fungsi Kepolisian" param={kesatuan}  />
          } />
          <DivCustom label="SubFungsi Kepolisian"
            form={
              <SelectSubFungsiKepolisian onChange={(e) => SendKedinasan('sub_fungsi_kepolisian', e.value)} value={sub_fungsi_kepolisian_id} placeholder="Pilih Sub Fungsi Kepolisian" param={fungsi_kepolisian_id}  />
          } />

         <DivCustom label="Kedinasan"
            form={
              <SelectSotk onChange={(e) => SendKedinasan('sotk', e.value)} value={sotk_id} placeholder="Pilih Kedinasan" param={sub_fungsi_kepolisian_id} golongan={"KEDINASAN"} />
          } />
         {session?.kode_jenis_user == "EXTERNAL" ?(<>
          <DivCustom label="Satuan Kerja"
            form={
              <SelectSotk onChange={(e) => SendPangDanSotk('sotk', e.value)} value={sotk_id} placeholder="Pilih Satuan Kerja" param={kesatuan} golongan={"SATUAN KERJA"} />
          } />
        </>):(<></>)}
        <DivCustom label="Tanggal Ditetapkan"
          form={
            <DatePicker className='w-full' value={tgl_ditetapkan ? moment(tgl_ditetapkan, 'YYYY-MM-DD') : ""} onChange={(e, d) => SendKedinasan('tgl_ditetapkan', d)} picker="date" />
          } />
        <DivCustom label="Tanggal Berakhir"
          form={
            <DatePicker className='w-full' value={tgl_berakhir ? moment(tgl_berakhir, 'YYYY-MM-DD') : ""} onChange={(e, d) => SendKedinasan('tgl_berakhir', d)} picker="date" />
        } />

      </div>
       <div className="grid xl:grid-cols-2 md:grid-cols-2 gap-3">
        <div className='flex'>
          <input type={"checkbox"} onChange={e => SendKedinasan('saat_ini', !saat_ini)} checked={saat_ini} /> <span className='ml-2'>Kedinasan Saat ini</span>
        </div>
      </div>

    </>
  )
}


export function FormUnitFungsional(props) {
  const [saat_ini, setSaatIni] = useState(false);
  const [rowid, setRowId] = useState('');
  const [unit_fungsional, setUnitFungsional] = useState('');
  const [kesatuan, setKesatuan] = useState('');
  const [tgl_berakhir, setTglBerakhir] = useState('');
  const [tgl_ditetapkan, setTglDitetapkan] = useState('');
  const [polda_id, setPoldaId] = useState('');
  const [polres_id, setPolresId] = useState('');
  const { data: session, status } = useSession()
  useEffect(() => {
    setData(props.data)
    props.data ? '' : reset_elm()
  }, [props.data])


  const setData = (values) => {
    if (values[0] != undefined) {
      const value = values[0]
      setUnitFungsional(value.unit_fungsional != undefined ? value.unit_fungsional : "")
      setPoldaId(value.polda_id)
      setPolresId(value.polres_id)
      setKesatuan(value.kesatuan)
      setTglDitetapkan(moment(value.tgl_ditetapkan).format('YYYY-MM-DD'))
      setTglBerakhir(moment(value.tgl_berakhir).format('YYYY-MM-DD'))
      setSaatIni(value.saat_ini)
    }
  }

  const reset_elm = () => {
    setUnitFungsional('')
    setTglDitetapkan('')
    setTglBerakhir('')
    setKesatuan('')
    setPoldaId('')
    setPolresId('')
    setSaatIni(false)
  }

  const sendUnitFungsional = (param, d) => {
    var data = {
      kode_user_master: session?.kode_user_master,
      polda_id: polda_id,
      polres_id: polres_id,
      ctdby: session?.kode_user_master,
    }

    if (param == "saat_ini") {
      d ? setSaatIni(d) : ""
      data.saat_ini = d
      data.tgl_ditetapkan = tgl_ditetapkan
      data.tgl_berakhir = tgl_berakhir
      data.unit_fungsional = unit_fungsional
   
    } else if (param == "tgl_ditetapkan") {
      d ? setTglDitetapkan(d) : ""
      data.tgl_ditetapkan = d
      data.saat_ini = saat_ini
      data.tgl_berakhir = tgl_berakhir
      data.unit_fungsional = unit_fungsional
   
    } else if (param == "tgl_berakhir") {
      d ? setTglBerakhir(d) : ""
      data.tgl_berakhir = d
      data.saat_ini = saat_ini
      data.tgl_ditetapkan = tgl_ditetapkan
      data.unit_fungsional = unit_fungsional
   
    } else if (param == "unit_fungsional") {
      d ? setUnitFungsional(d) : ""
      data.unit_fungsional = d
      data.saat_ini = saat_ini
      data.tgl_ditetapkan = tgl_ditetapkan
      data.tgl_berakhir = tgl_berakhir
   
    } else if (param == "polres") {
      d ? setPolresId(d) : ""
      data.polres_id = d
      data.saat_ini = saat_ini
      data.tgl_ditetapkan = tgl_ditetapkan
      data.tgl_berakhir = tgl_berakhir
      data.polda_id = polda_id
      data.unit_fungsional = unit_fungsional
      
    } else if (param == "polda") {
      d ? setPoldaId(d) : ""
      data.polda_id = d
      data.saat_ini = saat_ini
      data.tgl_ditetapkan = tgl_ditetapkan
      data.tgl_berakhir = tgl_berakhir
      data.polres_id = polres_id
      data.unit_fungsional = unit_fungsional
    }
    const { callBack } = props
    console.log(data);
    callBack(data)
  }

  return (
    <>
      <div className="grid xl:grid-cols-2 md:grid-cols-2 gap-3">
        <DivCustom label="Nrp"
          form={
            <>
              <InputCustom value={session?.id_unique} placeholder="Nama Anggota" readOnly={true} />
            </>
          }
        />
        <DivCustom label="Nama"
          form={
            <InputCustom value={session?.nama} placeholder="Nama Anggota" readOnly={true} />
          } />
      </div>
      <div className="grid xl:grid-cols-2 md:grid-cols-2 gap-3">
        <DivCustom label="Hirarki"
              form={
                <SelectCustom onChange={(e) => setKesatuan(e.value)} value={kesatuan} placeholder="Pilih Kesatuan">
                  {
                    [
                      { label: 'MABES', value: 'MABES' },
                      { label: 'POLDA', value: 'POLDA' },
                      { label: 'POLRES', value: 'POLRES' },
                    ]
                  }
                </SelectCustom>
              }
            />
        {kesatuan == "POLDA" || kesatuan == "POLRES" ? (<>    
        <DivCustom label="Polda"
          form={
            <SelectPolda onChange={(e) => sendUnitFungsional('polda', e.value)} value={polda_id} placeholder="Pilih Polda" />
          } />
        </>) : ""}
        {kesatuan == "POLRES" ? (<>
        <DivCustom label="Polres"
          form={
            <SelectPolres onChange={(e) => sendUnitFungsional('polres', e.value)} value={polres_id} param={polda_id} placeholder="Pilih Polres" />
          } />
          </>) : ""}
          
        <DivCustom label="Unit Fungsional"
          form={
            <SelectModul onChange={(e) => sendUnitFungsional('unit_fungsional', e.value)} value={unit_fungsional} placeholder="Pilih Unit Fungsional" />
        } />
        <DivCustom label="Tanggal Ditetapkan"
          form={
            <DatePicker className='w-full' value={tgl_ditetapkan ? moment(tgl_ditetapkan, 'YYYY-MM-DD') : ""} onChange={(e, d) => sendUnitFungsional('tgl_ditetapkan', d)} picker="date" />
        } />
        <DivCustom label="Tanggal Berakhir"
          form={
            <DatePicker className='w-full' value={tgl_berakhir ? moment(tgl_berakhir, 'YYYY-MM-DD') : ""} onChange={(e, d) => sendUnitFungsional('tgl_berakhir', d)} picker="date" />
        } />
       
      </div>
      
      <div className="grid xl:grid-cols-2 md:grid-cols-2 gap-3">
        <div className='flex'>
          <input type={"checkbox"} onChange={e => sendUnitFungsional('saat_ini', !saat_ini)} checked={saat_ini} /> <span className='ml-2'>Unit Fungsional Saat ini</span>
        </div>
      </div>

      
    </>
  )
}
