import React, { useEffect, useState } from 'react'
import { Table, Spin, message } from 'antd'
import Image from 'next/image';
import { InputCustom, SearchCustom } from '../MainCode/MainForm'
import StorageApi from '../APIConfig/MainApi';
import { Excel } from "antd-table-saveas-excel";
import { css } from '@emotion/css';

function DataTableGroup(props) {
  let {column,dataSource,rangeTime=[],customSubTitle,colorText,width} = props
  const [paging, setPagnition] = useState(10);
  const { Column, ColumnGroup } = Table;
  const [columns, setColumns] = useState([]);
  const [filterText, setFilterText] = useState('');
  const [total_rows, setColumnsNow] = useState(0);
  const [loader_download, setLoaderDownload] = useState(false);
  const [download_file, setDownloadFile] = useState(false);
  const [list_download, setListDownload] = useState([]);
  const [data_download, setDataDownload] = useState([]);

  useEffect(() => {
    // untuk columns
    var column = props.columns;
    if (props.nomor != 'hidden') {
      Column({
        title: 'No',
        dataIndex: 'no',
        width: '3%',
        align: 'center',
        fixed: 'left',
        sorter: (a, b) => a.no - b.no,
      })
    }
    setColumns(column)
    // props.download ? DownloadExcel(props.download) : ("")
  }, [props.columns])

  const DataDownload = (link) => {
    setLoaderDownload(true)
    Promise.resolve(StorageApi.getData(link))
      .then(value => {
        const label = Object.keys(value.data.data[0]);
        const data = value.data.data;
        var title_download = [
          { title: "No", dataIndex: "no" }
        ]
        var data_download = []
        var data_real = []
        let judul = {}
        let data_mentah = []
        let data_array_1 = {}
        let number = []
        for (let index = 0; index < label.length; index++) {
          if (label[index].slice(-3) != "_id" && label[index] != "rowid") {
            title_download.push(
              { title: label[index], dataIndex: label[index] },
            )
          }
        }
        for (let index = 0; index < data.length; index++) {
          let coba = Object.keys(data[index]);
          judul = Object.keys(data[index])
          data_mentah.push(
            Object.values(data[index])
          )
        }

        for (let i = 0; i < data_mentah.length; i++) {
          number.push({ no: i + 1 })
          for (let a = 0; a < data_mentah[i].length; a++) {
            var m = judul[a]
            if (m.slice(-3) != "_id" && m != "rowid") {
              data_array_1 = { ...data_array_1, [m]: data_mentah[i][a] }
            }
          }
          data_real.push(data_array_1)
          data_download.push({ ...data_real[i], "no": number[i].no });
        }
        setListDownload(title_download)
        setDataDownload(data_download)
        setLoaderDownload(false)
      }).catch(error => {
        setListDownload([])
        setDataDownload([])
        setLoaderDownload(false)
      })
  }

  const DownloadExcel = async (link) => {
    DataDownload(link)
    const excel = new Excel();
    excel
      .addSheet(`${props.filename}`)
      .addColumns(list_download)
      .addDataSource(data_download, {
        str2Percent: true
      })
    if (excel.currentRow != total_rows) {
      excel.saveAs(`${props.filename}.xlsx`)
      setDownloadFile(false)
      setColumnsNow(excel.currentRow)
    } else {
      message.success("Data berhasil diperbaharui")
      setColumnsNow(excel.currentRow)
      setDownloadFile(true)
    }
  };

  const tableCSS = css({
    // backgroundColor: 'white',
    '& table': {
      borderCollapse: 'collapse',
      borderWidth: '1px',
      borderColor: '#B6BBC4'
    },
    '& thead > tr > th': {
      backgroundColor: '#19376D',
      color: 'white',
      borderWidth: '1px',
      borderColor: '#B6BBC4',
    },
    '& thead > tr': {
      borderWidth: '1px',
      borderColor: 'white',
    },
    '& tbody > tr > td': {
      // backgroundColor : '#F6F1F1',
      color: 'black',
      borderWidth: '2px',
      borderColor: '#B6BBC4',
    }
  });

  return (
    <>
      {props.download ? (<>
        <div className=''>
          <div className="flex items-center justify-end">
            {props.import ? (<>
              <button className=" -mt-14 xl:mt-5 md:mt-5 mr-4 py-3 px-4 rounded-md bg-[#7366FF] hover:bg-[#3f3892] font-semibold flex text-white" onClick={props.import}>
                <Image src='/icons/upload.svg' width={23} height={23} alt={'import'} />
                &nbsp; Import
              </button>
            </>) : (<></>)}
            <button className=" -mt-14 xl:mt-5 md:mt-5 py-3 px-4 rounded-md bg-sky-400 hover:bg-sky-700 font-semibold flex text-white" onClick={e => DownloadExcel(props.download)}>
              {loader_download ? (<>
                <Spin />
                &nbsp; {download_file ? 'Proses Download' : 'Pembaruan data'}
              </>) : (<>
                <Image src="/icons/download.svg" alt="unduh" width={20} height={20} className="mr-2" />
                &nbsp;  {download_file ? 'Download Excel' : 'Perbarui data'}
              </>)}
            </button>
          </div>
        </div>
      </>) : (<></>)}
      {props.downloads ? (
        <button className=" -mt-14 xl:mt-5 md:mt-5 py-3 px-4 rounded-md bg-sky-400 hover:bg-sky-700 font-semibold flex text-white" onClick={props.downloads}>
          {props.prosesDownload ? (<>
            <Spin />
          </>) : (<>
            <Image src="/icons/download.svg" alt="unduh" width={20} height={20} className="mr-2" />
          </>)}
          &nbsp; Download
        </button>
      ) : ("")}
      {/* <SearchCustom title={props.title} TitleColor={'text-black'} type="text" onKeyUp={e => setFilterText(e.target.value)} placeholder="Search" nrp="search" disableSearch={props.disableSearch} /> */}
      <div className="flex flex-col">
        <div className="flex justify-start items-center">
          <InputCustom type="number" width="w-[60px] mb-2" onChange={e => setPagnition(e.target.value)} /> <span className='text-[12px] text-black font-light -ml-3'><b>{paging} rows/Page</b></span>
        </div>
        <div className='overflow-x-auto'>
          <div className=''>
            <Table 
            dataSource={dataSource}
            loading={props.loading}
            size={'medium'}
            className={tableCSS}
            pagination={
              {
                pageSize: paging ? paging : 10,
                position : ['bottomRight'] 
              }
            }
            scroll={{ x: props.Tscroll ? props.Tscroll :  '95%'}}
            tableLayout={{ width: "100vw" }}
            bordered
            >
              {columns.map(item=>{
                let subdataRange = []
                if (rangeTime.length > 0 && rangeTime[0]) {
                  if (item.dataSub) {
                    subdataRange = item.dataSub.filter(item => item.title >= rangeTime[0] && item.title <= rangeTime[1])
                  }
                }else{
                  subdataRange = item.dataSub
                }
                return(
                  <>
                    {item.groupTitle ? <>
                      <ColumnGroup title={item.title} className='!text-center'>
                      { subdataRange.map(item=>{
                          return(<>
                          <Column title={item.title} dataIndex={item.dataIndex} className='!text-center'/>
                          </>)
                        })
                      }
                      </ColumnGroup>
                    </> : <>
                    <Column title={item.title} dataIndex={item.dataIndex} className='!text-center' fixed={item.fixed ? item.fixed : 'none' }/>
                    </>}
                  </>
                )
              })}
            </Table>
          </div>
        </div>
      </div>
    </>
  )
}
export default DataTableGroup
