import Image from 'next/image';
import React, { useState } from 'react'
import { DivCustom, InputCustom } from '../MainCode/MainForm'

export default function FormPassword(props) {
  const [password, setPassword] = useState('');
  const [repassword, setRePassword] = useState('');
  const [notifikasi, setNotif] = useState('');
  const [pwdshow, setpwdshow] = useState(false);
  const [repwdshow, setrepwdshow] = useState(false);


  const CekPassword = (e) => {
    const retype = e.target.value;
    if (password == retype) {
      setRePassword(e.target.value)
      const { handlePassword } = props
      handlePassword(password)
      setNotif('')
    } else {
      setNotif("Password tidak sama, silahkan cek kembali password anda")
    }
  }
  return (
    <>
      <DivCustom label="Password"
        form={
          <div className="flex">
            <InputCustom type={pwdshow ? 'text' : 'password'} name="value" placeholder={"Password"} value={password} onChange={(e) => setPassword(e.target.value)} />
            <button onClick={() => setpwdshow(!pwdshow)} className='ml-1 border hover:bg-[#E6EAF0] bg-white px-2 rounded-md'><Image src={pwdshow ? "/icons/eye-unshow.svg" : "/icons/eye-show.svg"} alt="image" width={30} height={30} /></button>
          </div>
        } />

      <DivCustom label="Retype Password"
        form={
          <>
            <div className="flex">
              <InputCustom type={repwdshow ? 'text' : 'password'} name="value" placeholder={"Retype Password"} onChange={(e) => CekPassword(e)} />
              <button onClick={() => setrepwdshow(!repwdshow)} className='ml-1 border hover:bg-[#E6EAF0] bg-white px-2 rounded-md'><Image src={repwdshow ? "/icons/eye-unshow.svg" : "/icons/eye-show.svg"} alt="image" width={30} height={30} /></button>
            </div>
            <span className='text-red-500 text-[10px] font-semibold'>{notifikasi ? notifikasi : ""}</span>
          </>
        } />
    </>
  )
}
